import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable, of } from 'rxjs';
import { Mirador } from '../models/mirador';
import { MiradorSaved } from '../models/mirador-save';
import { User } from '../models/user';

const TABLE = "saves";

@Injectable({
  providedIn: 'root'
})
export class SavedManagerService {

  constructor(public fireStore: AngularFirestore) { }

  SetSavedData(user: User, mirador: Mirador) : Promise<void> {
    const saveId = this.fireStore.createId();
    const saveRef: AngularFirestoreDocument<any> = this.fireStore.doc(
      `${TABLE}/${saveId}`
    );
    const savedData: MiradorSaved = {
      saveId: saveId,
      userId: user.uid,
      miradorId: mirador.miradorId
    };
    return saveRef.set(savedData, {
      merge: true,
    }).then(() => {

    });
  }

  removeSave(saveId) {
    const itemDoc =  this.fireStore.doc(
      `${TABLE}/${saveId}`
    );
    return itemDoc.delete();
  }

  getMySavesMiradores(user: User) : Observable<(MiradorSaved & { saveId: string; })[]> {
    if(user && user.uid){
      return this.fireStore.collection<MiradorSaved>(TABLE, ref => ref.where('userId' , '==' , user.uid))
      .valueChanges({idField: 'saveId'});       
    }else {
      return of([]);
    }
    
  }
}
