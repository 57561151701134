import { Component, NgZone, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from 'src/app/models/user';
import { AlertController, Platform, ToastController } from '@ionic/angular';
import { AuthService } from './services/auth.service';
import { UtilService } from './services/util.service';
import { UsersManagerService } from './services/users-manager.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { NavigationEnd, Router } from '@angular/router';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { UserToMigrate } from './models/userToMigrate';
import { VisitsManagerService } from './services/visits-manager.service';
import { LikeManagerService } from './services/like-manager.service';
import { SavedManagerService } from './services/saved-manager.service';
import { Loader } from '@googlemaps/js-api-loader';
import { PushNotificationService } from './services/push-notification.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { RemoteConfigService } from './services/remote-config.service';
import { TranslateService } from '@ngx-translate/core';

import { } from '@angular/fire/analytics';

const loader = new Loader({
  apiKey: 'AIzaSyDUkAPb57nZm7cINe02A_RBeQG22nduUPQ',
  version: 'weekly',
});

const DEFAULT_LANG = "es";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  browser;
  OS;
  countResize: number = 0;
  tabsPositionOk: boolean = false;

  operationSystem: string;
  isMobileWeb: boolean;
  showBanner: boolean = true;  // Controla si se muestra el banner
  isIOS: boolean;
  apiLoaded: boolean;
  darkMode: boolean = false;

  isMiradoresTeam: boolean;

  currentSesion: User = null;
  mapApiLoaded: boolean = false;
  token = '';


  constructor(
    public authService: AuthService,
    httpClient: HttpClient,
    private platform: Platform,
    private utilService: UtilService,
    private toastController: ToastController,
    public auth: AuthService,
    public userServices: UsersManagerService,
    private zone: NgZone,
    private router: Router,
    public visitService: VisitsManagerService,
    public likeService: LikeManagerService,
    public saveService: SavedManagerService,
    private alertController: AlertController,
    private pushNotificationService: PushNotificationService,
    private gtmService: GoogleTagManagerService,
   /*  private remoteConfigService: RemoteConfigService, */
    public translate: TranslateService
  ) {
    this.gtmService.addGtmToDom();

    loader.load().then(() => {
      this.mapApiLoaded = true;
    });

    this.initializeDinamicLink();
    this.getSession();
    this.platform.ready().then(() => {
      this.checkDarkTheme();
    });

    this.pushNotification();
    
    this.isIOS = this.platform.is('ios');

    this.initTranslateService();
  }

  ngOnInit() {
    this.checkForAppUpdate();
    this.isMobileWeb = this.platform.is('mobile') && !this.platform.is('hybrid');
    this.showBanner = this.isMobileWeb? true : false;  // Inicialmente, el banner se muestra si isMobileWeb
    console.log('this.isMobileWeb =>',this.isMobileWeb,  this.platform.is('mobile'), this.platform.is('hybrid'));

    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);

        // Controlar si se debe mostrar el banner dependiendo de la ruta
      if (this.isMobileWeb) {  // Solo nos interesa si es mobile web
        this.showBanner = !item.url.includes('/experience/');
      }
      }
    });
  }

  async checkForAppUpdate() {
    /* await this.remoteConfigService.checkForUpdate(); */
  }

  openLink() {
    const link = "https://miradores.page.link/?link=https://med.miradores.co/mirador/fonda.mirador.el.encanto&apn=co.miradores.med&afl=https://play.google.com/store/apps/details?id=co.miradores.med&ibi=co.miradores.med&isi=6449554555&efr=1";
    window.open(link, "_blank")
  }


  async pushNotification() {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will simply grant without prompting until version 12
    try {
      let permStatus = await PushNotifications.checkPermissions();

      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
      }

      if (permStatus.receive !== 'granted') {
        throw new Error('User denied permissions!');
      }

      await PushNotifications.addListener('registration', (token: Token) => {
        this.token = token.value;
        console.log('Push registration success, token: ' + token.value);
        this.pushNotificationService.saveToken(this.token);
      });

      await PushNotifications.addListener('registrationError', (error: any) => {
        console.log('Error on registration: ' + JSON.stringify(error));
      });

      // Show us the notification payload if the app is open on our device
      await PushNotifications.addListener(
        'pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          alert('Notificación: ' + notification.title);
          console.log(notification);
          if (notification.data) {
            const route = notification.data.route;
            const link = notification.data.link;

            if (link) {
              // Redirigir a enlace externo
              window.open(link, '_system');
            } else if (route) {
              // Redirigir a ruta interna utilizando la navegación de Angular
              this.zone.run(() => {
                this.router.navigateByUrl(route).catch((error) => {
                  console.error('Error navigating to route:', error);
                  this.router.navigateByUrl('/app/map');
                });
              });
            }
          }
        }
      );

      //allows capturing the event when the user pushes the notification to open the app
      await PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          if (notification.notification.data) {
            const route = notification.notification.data.route;
            const link = notification.notification.data.link;

            if (link) {
              // Redirigir a enlace externo
              window.open(link, '_system');
            } else if (route) {
              // Redirigir a ruta interna utilizando la navegación de Angular
              this.zone.run(() => {
                this.router.navigateByUrl(route).catch((error) => {
                  console.error('Error navigating to route:', error);
                  this.router.navigateByUrl('/app/map');
                });
              });
            }
          }
        }
      );
    } catch (error) { }
  }

  initializeDinamicLink() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const acceptedDomains = ['med.miradores.co', 'miradores.page.link'];
        const eventDomain = event.url.split('/')[2]; // Extract the domain from the URL

        if (acceptedDomains.includes(eventDomain)) {
          let appPath = '';

          if (eventDomain === 'med.miradores.co') {
            const pathArray = event.url.split(eventDomain);
            appPath = pathArray.pop();
          } else if (eventDomain === 'miradores.page.link') {
            const startIndex = event.url.indexOf('link=') + 'link='.length;
            const endIndex = event.url.indexOf('&', startIndex); // Find the end of the path
            const fullPath = event.url.substring(startIndex, endIndex !== -1 ? endIndex : undefined);
            const url = new URL(fullPath);
            appPath = url.pathname;

            if (appPath === '/map') {
              appPath = '/app/map';
            } else if (appPath === '/explore' || appPath === '/explorar') {
              appPath = '/app/explore';
            } else if (appPath === '/experience' || appPath === '/experiences') {
              appPath = '/app/explora';
            } else if (appPath === '/iniciar' || appPath === '/login') {
              appPath = '/login';
            }
          }

          console.log(eventDomain, appPath);
          if (appPath) {

            this.router.navigateByUrl(appPath);
          }
        }
      });
    });
  }

  getSession() {
    this.auth.$establishedSession.subscribe((user) => {
      let photoURL = user?.photoURL
        ? user?.photoURL + '?type=large&return_ssl_resources=1'
        : '';
      this.currentSesion = { ...user, photoURL };

      this.isMiradoresTeam =
        this.currentSesion.uid === 'FW33GcNgH8eYFgOqFQQvvepWu9W2' ||
        this.currentSesion.uid === 'TeENcTMuBbbdXEOMdKtdNoXstEi2' ||
        this.currentSesion.uid === 'ohsEOkHFhEYO6I2bPtQOs3uM4FG3' ||
        this.currentSesion.uid === 'gqFCPhixgPTl6m8juX99PYFtgA12' ||
        this.currentSesion.uid === 'vgbt5peMDzYD2xWVveHeBwdzQYt1' ||
        this.currentSesion.uid === 'ZzFdymIEFBeE0bh150K5NtlLml13';
    });
  }

  checkDarkTheme() {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    this.darkMode = prefersDark.matches || true;
    if (this.darkMode) {
      document.body.classList.toggle('dark');
      this.utilService.setTheme(this.darkMode);
    }
  }

  cambio() {
    // const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    this.darkMode = !this.darkMode;
    document.body.classList.toggle('dark');
    this.utilService.setTheme(this.darkMode);
  }

  closeSession() {
    this.authService.SignOut();
  }

  initTranslateService(){

    let userlang = navigator.language.substring(0, 2) || DEFAULT_LANG;

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(DEFAULT_LANG);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(userlang);

  }

  onLangChange(langEvent) {

    let newLang = langEvent.detail.value;
    this.translate.use(newLang);
  }

  prevent(evt: Event) {
    evt.preventDefault();
  }
}
