import { Moment } from "moment";

export class BusinessHour {
  public contentSplitted: string[];
  public contentToDisplay: any[];
  public isOpen : boolean | null;
  public labelForToday : string | null;
  public startHourToday: Moment | null;
  public endHourToday: Moment | null;
  public showMinutes: boolean | null;
  public labelToDisplay: string | null;

  constructor(public type: number, public content: string) {
    this.showMinutes = false;
    this.contentToDisplay = [];
    switch (this.type) {
      case 1: 
        this.labelForToday = content;
        this.isOpen = true;
        break;
      case 2:
        this.labelForToday = "Todos los dias";
        break;
      case 3:
        this.contentSplitted = this.content.split(';');
        break;
    }
  }

}
