import { ViewsExperiences } from "../views-experiences";

type ExperienceType = keyof typeof typeExperience;

export class Experience {
    _id: string;
    companyId: string;
    title: string;
    description: string;
    longDescription?: string;
    urlCovers: string[];
    tags?: string[];
    prices: ItemPrice[];
    bonus?: string[];
    urlTestimonials?: string[];
    urlMicromoments?: string[];
    videos?: Video[];
    collaborators?: string[];
    reviews?: Review[];
    routes: Point[];
    includes?: Include[];
    considerations?: Consideration[];
    itinerary?: string[];
    ratingScore?: number;
    averageScore?: number;
    numReviews?: number;
    expectedTime?: string;
    isDefinedDates?: boolean;
    definedDates?: DefinedDate[];
    contactsWhatsApp?: ContactWhatsApp[];
    en?: any;
    state: boolean;
    views?: ViewsExperiences[];
    experienceType?: ExperienceType;
    filmakerConDrone?: boolean;
    amountForFlexibleReservation?: number;
    minAmountPeople?: number;
    mainCityRegionId: string;
    city: string;


    constructor(experienceData: any) {
        this._id = experienceData._id;
        this.companyId = experienceData.companyId;
        this.title = experienceData.title;
        this.description = experienceData.description;
        this.longDescription = experienceData.longDescription;
        this.urlCovers = experienceData.urlCovers;
        this.tags = experienceData.tags;
        this.prices = experienceData.prices;
        this.bonus = experienceData.bonus;
        this.urlTestimonials = experienceData.urlTestimonials;
        this.urlMicromoments = experienceData.urlMicromoments;
        this.videos = experienceData.videos || [];
        this.collaborators = experienceData.collaborators;
        this.reviews = experienceData.reviews;
        this.routes = experienceData.routes;
        this.includes = experienceData.includes;
        this.considerations = experienceData.considerations;
        this.itinerary = experienceData.itinerary;
        this.ratingScore = experienceData.ratingScore;
        this.averageScore = experienceData.averageScore;
        this.numReviews = experienceData.numReviews;
        this.expectedTime = experienceData.expectedTime;
        this.isDefinedDates = experienceData.isDefinedDates;
        this.definedDates = experienceData.definedDates;
        this.contactsWhatsApp = experienceData.contactsWhatsApp;
        this.state = experienceData.state;
        this.views = [];
        this.experienceType = experienceData.experienceType;
        this.filmakerConDrone = experienceData.filmakerConDrone
        this.amountForFlexibleReservation = experienceData.amountForFlexibleReservation;
        this.minAmountPeople = parseInt(experienceData.minAmountPeople);
        this.mainCityRegionId = experienceData.mainCityRegionId;
        this.city = experienceData.city;


        if (experienceData.en) {
            this.en = experienceData.en;
        } else {
            this.en = JSON.parse(JSON.stringify(this));
        }
    }
}

/* export const typeExperience = {
    PRIVATE_VARIABLE_PRICE_RESERVATION: "Experiencia Privada - Reserva Con Precio variable Según Numero De Personas",
    PRIVATE_FIXED_CAPACITY_RESERVATION: "Experiencia Privada - Reserva Con cupo exacto requerido  (Pago Manual)",
    PRIVATE_DEPOSIT_RESERVATION: "Experiencia Privada - Reserva Con Deposito Fijo",
    COLECTIVE_FIXED_PRICE_RESERVATION: "Experiencia Colectiva - Reserva Con Precio fijo Por Persona",
    COLECTIVE_FREE_RESERVATION: "Experiencia Colectiva - Reserva Gratuita (Reserva Individual)",
  }; */

export const typeExperience = {
PRIVATE_VARIABLE_PRICE_RESERVATION: "Experiencia Privada",
PRIVATE_FIXED_CAPACITY_RESERVATION: "Privada Con Cupo Exacto",
PRIVATE_DEPOSIT_RESERVATION: "Privada Con Deposito Fijo",
COLECTIVE_FIXED_PRICE_RESERVATION: "Grupal con transporte incluido",
COLECTIVE_FREE_RESERVATION: "Gratuito para la comunidad de miradores",
};

export class ItemPrice {
    amountOfPeople: number;
    priceByPerson: string;
    priceByPersonCop?: string;
}

export class Video {
    urlCover: string;
    urlVideo: string;
    duration?: string;
}


export class DefinedDate {
    definedDate: number;
    reserveId?: string;
}

export class ContactWhatsApp {
    contactWhatsApp: {
        countryCode: string;
        dialCode: string;
        e164Number: string;
        internationalNumber: string;
        nationalNumber: string;
        number: string;
    }
}

export class Review {
    id: string;
    userId: string;
    ratingScore: number;
    comment: string;
}

export class Point {
    name: string;
    geoPos: {
        lat: string;
        lng: string;
    };

    constructor(name: string, lat, lng) {
        this.name = name;
        this.geoPos = { lat: lat, lng: lng };
    }
}

export class Include {
    icon: string;
    title: string;
    description: string;
}

export class Consideration {
    icon: string;
    title: string;
    description: string;
}

export class MarkersExperience {
    experience?: Experience
    mapOptionObj: google.maps.MarkerOptions;
    mapPositionObj: { lat: number; lng: number };
}
