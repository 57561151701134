import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-install-banner',
  templateUrl: './install-banner.component.html',
  styleUrls: ['./install-banner.component.scss']
})
export class InstallBannerComponent {
  @Input() showBanner: boolean = false;

  closeBanner(): void {
    this.showBanner = false;
  }
}