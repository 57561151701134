import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { BodyNotificationPush, PushNotification, PushNotificationResponse } from '../models/notification-push';
import { Observable, from, map } from 'rxjs';
import { User } from '../models/user';
import { Post } from '../models/post';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Ng2ImgMaxService } from 'ng2-img-max';


const TABLE = 'notifications';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  private collectionRef: AngularFirestoreCollection<PushNotification>;
  registrationDevicesUser: string[]


  constructor(private db: AngularFirestore, private http: HttpClient,private ng2ImgMax: Ng2ImgMaxService) {
    this.collectionRef = this.db.collection(TABLE);
  }

  getUidPushNotifications(uid: string): Observable<PushNotification[]> {
    return from(this.collectionRef.ref.where('uid', '==', uid).get()).pipe(
      map(querySnapshot =>
        querySnapshot.docs.map(doc => {
          const data = doc.data() as PushNotification;
          const id = doc.id;
          return { id, ...data };
        })
      )
    );
  }

  sendLikeVisitPushNotification(user: User, userOwnerId: string) {
    if(user.uid === userOwnerId) {
      return
      console.log('el usuario hace like en su propia visita');
    } else {
      this.registrationDevicesUser = []
      this.getUidPushNotifications(userOwnerId).subscribe(notifications=>{
        notifications.forEach((notification: PushNotification)=>{
          this.registrationDevicesUser.push(notification.tokenDevice)
        })

        let bodyNotificationPush: BodyNotificationPush = {
          notification: {
            title: `Recibiste un like en tu visita ❤️`,
            body: `${user.displayName || 'Alguien'} ha dado like a tu visita! Conoce quién y explora más miradores 🌆👀`,
          },
          data: {
            route: `/else-profile/${user.uid}`,
            link: ''
          },
          tokens: this.registrationDevicesUser
        }
        if (this.registrationDevicesUser.length > 0) {
          this.sendPushNotification(bodyNotificationPush).subscribe(response=>{
            // console.log(response);
          })
        }
      })
    }
  }

   sendPushNotification(
    body: BodyNotificationPush
  ): Observable<PushNotificationResponse> {
    return this.http.post<PushNotificationResponse>(
      environment.apiBackendMiradores + '/notification-push/send',
      body
    )
  }

  saveToken(token: string) {
    const tokenData = {
      tokenDevice: token,
      date: new Date(),
    };

    localStorage.setItem('tokenDevice', token);

    return this.collectionRef
      .add(tokenData)
      .then(() => {
        console.log('Token guardado en la colección.');
      })
      .catch((error) => {
        console.log('Error al guardar el token:', error);
      });
  }

  updateTokenWithUid(tokenDevice: string, uid: string) {
    // Consultar en la colección 'notifications' si existe un documento con el tokenDevice
    this.collectionRef.ref
      .where('tokenDevice', '==', tokenDevice)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          // No existe un documento con el tokenDevice, no se hace nada
          // console.log('No existe un documento con el tokenDevice:', tokenDevice);
        } else {
          // Existe un documento con el tokenDevice, se actualiza el campo uid
          const docId = querySnapshot.docs[0].id;

          return this.collectionRef
            .doc(docId)
            .update({ uid: uid })
            .then(() => {
              console.log('Token actualizado en la colección.');
            })
            .catch((error) => {
              console.log('Error al actualizar el token:', error);
            });
        }
      })
      .catch((error) => {
        console.log('Error al consultar la colección:', error);
      });
  }
}
