import { Timestamp } from "@angular/fire/firestore";
import { User } from "./user";
import { Visit } from "./visit";
import { Mirador } from "./mirador";
import { VisitExperience } from "./visit-experience";
import { Experience } from "./ToursModels";

export class Post {
    postId: string;
    type: "NewVisitPost" | "NewMiradorPost" | "RankingPost" | "TopMiradorMonthPost" | "NewVisitExperiencePost" ;
    date: Date | Timestamp;
    userId?: string;
    miradorId?: string;
    visitId?: string;
    visitExperienceId: string;
    rankingId?: string;
    countLikes?: number;

    user?: User | {
        displayName: string,
        uid: string,
        photoURL: string
    };

    visit?: Visit | {
        visitId: string,
        urlPhoto: string,
        date: Date | Timestamp
    };

    mirador?: {
        name: string,
        miradorId: string,
        urlCover: string,
        covers?: string[],
        operation?: "Added" | "Updated"
    }

    visitExperience? : VisitExperience | {
        visitExperienceId: string,
        urlPhoto: string,
        date: Date | Timestamp
    }

    experience?: {
        name: string,
        experienceId: string,
        urlCover: string,
        covers?: string[],
        operation?: "Added" | "Updated"
    }

    content?: {
        items:any[],
        lastupdate:number,
        period: string,
        type: string
    }

    constructor(type: "NewVisitPost" | "NewMiradorPost" | "RankingPost" | "TopMiradorMonthPost" | "NewVisitExperiencePost",
                date: Date,
                user?: User,
                visit?: Visit,
                mirador?: Mirador,
                visitExperience?: VisitExperience,
                experience?: Experience
                ){
        this.type = type;
        this.date = date;
        this.countLikes = 0;

        switch(type) {
            case "NewVisitPost":
                this.userId = user.uid;
                this.visitId = visit.visitId;
                this.user = { displayName: user.displayName, uid: user.uid, photoURL: user.photoURL };
                this.visit = {  visitId: visit.visitId, urlPhoto: visit.urlPhoto, date: visit.date };
                this.mirador = { name: mirador.name, miradorId: mirador.miradorId, urlCover: mirador.covers[0] };
                break;

            case "RankingPost" || "TopMiradorMonthPost":
                this.content = {
                  items: [],
                  lastupdate: 0,
                  period: "",
                  type: ""
                };
                this.rankingId = "";
                break;

            case "NewVisitExperiencePost":
                  this.userId = user.uid;
                  this.visitExperienceId = visitExperience.visitId;
                  this.user = { displayName: user.displayName, uid: user.uid, photoURL: user.photoURL };
                  this.visitExperience = {  visitExperienceId: visitExperience.visitId, urlPhoto: visitExperience.urlPhoto, date: visitExperience.date };
                  this.experience = { name: experience.title, experienceId: experience._id, urlCover: experience.urlCovers[0] };
            break;
        }
    }
}

