import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable, Subject, firstValueFrom, of } from 'rxjs';
import { Mirador } from '../models/mirador';
import { MiradorLiked } from '../models/mirador-like';
import { User } from '../models/user';
import { Visit, VisitLike } from '../models/visit';
import { HomeService } from './home.service';
import { VisitsManagerService } from './visits-manager.service';

const TABLE = "visit-likes";

@Injectable({
  providedIn: 'root'
})
export class VisitLikesManagerService {

  private isVisitLikeProcessDone = new Subject<string>();
  public $isVisitLikeProcessDone = this.isVisitLikeProcessDone.asObservable();

  constructor(public fireStore: AngularFirestore, private homeService: HomeService, private visitService: VisitsManagerService) {

  }

  getLikesByUser(user: User) : Observable<(VisitLike)[]> {
    if(user && user.uid){
      return this.fireStore.collection<VisitLike>(TABLE, ref => ref
        .where('userId' , '==' , user.uid)) 
        .valueChanges({idField: 'likeId'});       
    }else {
      return of([]);
    }
  }


  SetLikedData(user: User, visitId: string) : void {
    const likeId = this.fireStore.createId();
    const likeRef: AngularFirestoreDocument<any> = this.fireStore.doc(
      `${TABLE}/${likeId}`
    );

    const likedData: VisitLike = {
      likeId: likeId,
      userId: user.uid,
      visitId: visitId
    };

    likeRef.set(likedData, {
      merge: true,
    }).then(() => {
        console.log("visitLike - like added");
        this.getAmountOfLikesByVisit(visitId).then((likes: number) => {
        this.homeService.updatedPostVisitLikesCount(visitId, likes).then(() => {
            this.visitService.updatedVisitLikesCount(visitId, likes).then(() => {
                this.isVisitLikeProcessDone.next(likeId);
            })
        });
      })  
    });
  }


  getAmountOfLikesByVisit(visitId: string) : Promise<(number)> {
    return new Promise((resolve) => {
      this.fireStore.collection<VisitLike>(TABLE, ref => ref.where('visitId' , '==' , visitId))
        .get().subscribe((query) => {
          let countVisits = query.size;
          resolve(countVisits);
        })
    })   
  }

  removeLike(likeId, visitId) {
    const itemDoc =  this.fireStore.doc(
      `${TABLE}/${likeId}`
    );
    return itemDoc.delete().then(() => {
        console.log("visitLike - like removed");
        this.getAmountOfLikesByVisit(visitId).then((likes: number) => {
          this.homeService.updatedPostVisitLikesCount(visitId, likes).then(() => {
            this.visitService.updatedVisitLikesCount(visitId, likes).then(() => {
                this.isVisitLikeProcessDone.next(likeId);
            })
        });
        })   
    });
  }

}
