import { Experience } from './experience';
export class Reservation {
    _id?: string;
    experienceId?: string;
    experienceTitle: string;
    numPeople: number;
    selectedDate: string;
    creationDate?: string;
    personPrice: number;
    totalPrice: number;
    state: ReserveState;
    userId?: string;
    userName?: string;
    empresaId?: string;
    colaboradorId?: string;
    referencePhoto?: string;
    cancellationReason?: string;
    hostMessage?: string;
    reservationByLoggedUser?: boolean;
    whatsapp?: string;
    city?: string;
    discountCode?: string;
    affiliateId?: string;
    isDroneRequired?: boolean;
    payment100ReserveActive?: boolean;
    experienceType?: string;
    maxCapacity?: number;
    registeredPeople?: Reservation[]
    availableSeats?: number
    registeredPeopleCount ?: number
    requiresPrivateTransport?: boolean;
    additionalCapacityForm?: {
        peopleToAdd?: People[];
        reservationStatus?: string;
        totalSeats?: number;
      };
    experience?: Experience;
    description?: string;
    whatsappGroupLink?: string;
    isNext?: boolean;


    constructor(reservationData: any ) {
        this._id = reservationData._id;
        this.experienceId = reservationData.experienceId;
        this.experienceTitle = reservationData.experienceTitle;
        this.numPeople = reservationData.numPeople;
        this.selectedDate = reservationData.selectedDate;
        this.creationDate = reservationData.creationDate;
        this.personPrice = reservationData.personPrice;
        this.totalPrice = reservationData.totalPrice;
        this.state = reservationData.state;
        this.userId = reservationData.userId;
        this.empresaId = reservationData.empresaId;
        this.colaboradorId = reservationData.colaboradorId;
        this.referencePhoto = reservationData.referencePhoto;
        this.cancellationReason = reservationData.cancellationReason;
        this.hostMessage = reservationData.hostMessage;
        this.whatsapp = reservationData.whatsapp;
        this.payment100ReserveActive = reservationData.payment100ReserveActive;
        this.experienceType = reservationData.experienceType;
        this.maxCapacity = reservationData.maxCapacity;
        this.requiresPrivateTransport = reservationData.requiresPrivateTransport;
        this.registeredPeople = reservationData.registeredPeople;
        this.additionalCapacityForm = reservationData.additionalCapacityForm;
        this.description = reservationData.description;
        this.whatsappGroupLink = reservationData.whatsappGroupLink;
    }

}

export enum ReserveState {
    Pending = 'Pendiente',
    SettingUp = 'Configurando reserva',
    Confirmed = 'Confirmada',
    InProgress = 'En curso',
    Completed = 'Finalizada',
    Canceled = 'Cancelada',
    NoShow = 'No Asistida/No Show',
  }


  export interface People {
    numPeople: number;
    whatsapp: string;
  }