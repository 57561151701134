// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-logo {
  display: block;
  margin: 0 auto 20px;
  padding: 20px;
  width: 60%;
}

@media only screen and (min-width: 1024px) {
  /* Estilos para pantallas con un ancho superior a 1023px */
  .desktop-mask {
    width: 1024px;
    margin: 0 auto;
  }
}
.custom-label {
  font-size: smaller;
  font-weight: 100;
}

.menu-list-wrapper {
  margin: 32px 12px;
  display: flex;
  justify-content: center;
}

.menu-button {
  margin: 0 -5px; /* Espaciado entre los botones */
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,mBAAA;EACA,aAAA;EACA,UAAA;AACJ;;AAEA;EACI,0DAAA;EACA;IACI,aAAA;IACA,cAAA;EACN;AACF;AAEE;EACE,kBAAA;EACA,gBAAA;AAAJ;;AAGE;EACE,iBAAA;EACA,aAAA;EACA,uBAAA;AAAJ;;AAGE;EACE,cAAA,EAAA,gCAAA;AAAJ","sourcesContent":[".image-logo {\r\n    display: block;\r\n    margin: 0 auto 20px;\r\n    padding: 20px;\r\n    width: 60%;\r\n}\r\n\r\n@media only screen and (min-width: 1024px) {\r\n    /* Estilos para pantallas con un ancho superior a 1023px */\r\n    .desktop-mask {\r\n        width: 1024px;\r\n        margin: 0 auto;\r\n    }\r\n  }\r\n\r\n  .custom-label {\r\n    font-size: smaller;\r\n    font-weight: 100;\r\n  }\r\n\r\n  .menu-list-wrapper {\r\n    margin: 32px 12px;\r\n    display: flex;\r\n    justify-content: center;\r\n  }\r\n  \r\n  .menu-button {\r\n    margin: 0 -5px; /* Espaciado entre los botones */\r\n  }\r\n\r\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
