import { IMAGES_PATH } from '../enums & constants/paths.constants';

export class Category {
    constructor(public id: number,
        public name: string,
        public desc: string,
        public iconUrl: string = '') {
        this.iconUrl = this.getUrlLogo();
    }

    getUrlLogo() {
        return !this.iconUrl.toLowerCase().startsWith("https://") ? `${IMAGES_PATH}${this.iconUrl}` : this.iconUrl;
    }
}

export const DEFAULT_CATEGORY: Category = {
    id: -1,
    name: "Publico",
    desc: "Mirador de caracter publico",
    iconUrl: `${IMAGES_PATH}/default.png`,

    getUrlLogo() {
        return !this.iconUrl.toLowerCase().startsWith("http://") ? `${IMAGES_PATH}/${this.iconUrl}` : this.iconUrl;
    }
}
