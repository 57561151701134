// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    // databaseURL: "https://miradores-ac574.firebaseio.com",
    apiKey: "AIzaSyDBzN7JNnkeGiN03whhByaDkiB1ojg1jZM",
    authDomain: "miradores-ac574.firebaseapp.com",
    projectId: "miradores-ac574",
    storageBucket: "miradores-ac574.appspot.com",
    messagingSenderId: "1016631070561",
    appId: "1:1016631070561:web:7da7247c93f845573e3f20",
/*     measurementId: "G-NQ7EMSCTHH" */
  },
  appShellConfig: {
    debug: false,
    networkDelay: 500
  },
  apiBackendMiradores:'https://us-central1-miradores-ac574.cloudfunctions.net/api',
  urlMiradores: 'https://med.miradores.co'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
