import { Multimedia } from './multimedia';
/* import { Tag } from './hash-tags'; */
import { Category, DEFAULT_CATEGORY } from './category';
import { IMAGES_PATH } from '../enums & constants/paths.constants';
import { BusinessHour } from './business-hour';
import { MiradorLiked } from './mirador-like';

export const LOCATION_EXCLUDE_WORDS = ["El", "La", "De", "Vereda", "Corregimiento"]

export const DELTA_TOTAL: number = 0.0005500000000;
export const DELTA_MED: number = 0.0002750000000;

export class Mirador {
    miradorId: string;
    name: string;
    score: number;
    description: string;
    location: string;
    lat: number;
    lng: number;
    category: Category;
    multimedia: Multimedia[];
    tags : string[];
    covers : string[];
    urlCover?: string;
    urlLogo?: string;
    nearbyNeighborhood?: string;
    sector?:string;
    disconnectionCategory?: string;
    timeArriving?: string;
    page?: string;
    foods?: string;
    foodRecommended?: string;
    tourEnable?: boolean;
    reel?: string;
    reelInstId?: string; 
    seeOnMapsUrl?: string;
    businessHours: BusinessHour;
    infoUrl?: string;
    menuUrl?: string;
    likes?: MiradorLiked[];
    locationData?: { city: string, neighborhood: string, referencePoint: string, zone: string }
    fullLocation?: string;
    mapPositionObj: google.maps.LatLngLiteral;
    mapOptionObj: google.maps.MarkerOptions;
    mapAreaPositionsObj: google.maps.Polygon;
    albums: string[];

    constructor(miradorData: any, category: Category = DEFAULT_CATEGORY ) {

        this.miradorId = miradorData.miradorId;
        this.name = miradorData.name;
        this.score = miradorData.score;
        this.description = miradorData.description;
        if(miradorData.latlng){
            this.lat = miradorData.latlng._lat;
            this.lng = miradorData.latlng._long;
        }else {
            this.lat = miradorData.lat;
            this.lng = miradorData.lng;
        }
        this.category = category;
        this.multimedia = new Array<Multimedia>();
        this.tags = miradorData.tags && miradorData.tags.split(" - ");
        this.covers = miradorData.covers;
        
        if(miradorData.covers) {
            this.urlCover = miradorData.covers[0];
        }else {
            this.urlCover = miradorData.urlCover;
        }

        this.urlLogo = miradorData.urlLogo;

        if(miradorData.locationData) {
            this.locationData = miradorData.locationData;
            this.location = miradorData.locationData.city;
            this.nearbyNeighborhood = miradorData.locationData.neighborhood;
            this.sector = miradorData.locationData.zone;
            this.generateFullLocation();
        }else {
            this.location = miradorData.location;
            this.nearbyNeighborhood = miradorData.nearbyNeighborhood;
            this.sector = miradorData.sector;
            this.fullLocation = `${this.nearbyNeighborhood}, ${this.sector}`
        }

        //this.disconnectionCategory = miradorData.disconnectionCategory; //TODO Borrar
        //this.timeArriving = miradorData.timeArriving; //TODO Borrar
        this.page = miradorData.page;
        this.infoUrl = miradorData.infoUrl;
        this.menuUrl = miradorData.menuUrl;
        this.foods = miradorData.foods;
        this.foodRecommended = miradorData.foodRecommended;
       //this.tourEnable = miradorData.tourEnable; //TODO Borrar
        this.reel = miradorData.reel;
        this.reelInstId = miradorData.reelInstId;
        this.mapPositionObj = this.GetGMapPosition();
        this.mapOptionObj = this.GetGMapOption();
        this.mapAreaPositionsObj = this.GetGMapAreaPolygon();
        this.businessHours = miradorData.businessHours ? new BusinessHour(miradorData.businessHours.type, miradorData.businessHours.content) : null;
        this.seeOnMapsUrl = miradorData.seeOnMapsUrl;
        this.likes = new Array<MiradorLiked>();
        this.albums = miradorData.albums;
        
    }

    generateFullLocation(){
        this.fullLocation = this.locationData.referencePoint;
        if(!this.fullLocation.toLowerCase().includes(this.locationData.zone.toLowerCase())) {
         this.fullLocation = `${this.locationData.zone}, ${this.fullLocation}`
        }

        if(!this.fullLocation.toLowerCase().includes(this.locationData.neighborhood.toLowerCase())) {
            this.fullLocation = `${this.locationData.neighborhood}, ${this.fullLocation}`
        }

    }

    // getNameWithOutExcludedWorks(word: string){
    //     let newStr = word;
    //     for (const excludeWord of LOCATION_EXCLUDE_WORDS) {
    //         const reg = /\d{3}/g
    //         newStr = newStr.replace(reg, "");
    //     }

    //     return newStr;
    // }

    //#region Img Methods

    getUrlCover() {
        if(this.covers) {
            return !this.covers[0].toLowerCase().startsWith("https://") ? `${IMAGES_PATH}miradores/${this.covers[0]}` : this.covers[0];
        } else if (this.urlCover){            
            return !this.urlCover.toLowerCase().startsWith("https://") ? `${IMAGES_PATH}miradores/${this.urlCover}` : this.urlCover;
        }else {
            return `${IMAGES_PATH}logo.png}`;
        }
     }
     
     getPlaceholder() {
     let defaultPlaceholder = `${IMAGES_PATH}miradores/default-cover.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_400,w_400`;
    //    if (this.urlCover) {
    //      defaultPlaceholder = `${IMAGES_PATH}miradores/${this.urlCover}?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_400,w_400`;
    //    }
       return defaultPlaceholder;
     }
     
     getUrlLogo() {
        if (this.urlLogo){
            return !this.urlLogo.toLowerCase().startsWith("https://") ? `${IMAGES_PATH}miradores/${this.urlLogo}` : this.urlLogo;
        }else {
            return `${IMAGES_PATH}logo.png}`
        }
     }

    //#endregion

    //#region  Google Maps Methods

    GetGMapPosition() : google.maps.LatLngLiteral {
        return {lat: this.lat, lng: this.lng};
    }

    GetGMapOption() : google.maps.MarkerOptions {
        return { draggable : false, title: this.name, icon: this.GetGMapIconNew() }
    }

   /*  GetGMapLabel() : google.maps.MarkerLabel {
        return { className:'miradorLabel '+this.category.name , text: this.name, color: 'white', fontSize: '10px'}
    } */

    GetGMapIconNew() : google.maps.Icon {
        let point = new google.maps.Point(0, -15);
        return { url: this.getUrlCover() , labelOrigin : point, scaledSize: new google.maps.Size(28,28) }
    }

    GetGMapIcon() : google.maps.Icon {
        let point = new google.maps.Point(0, -15);
        return { url: `${this.category.iconUrl}` , labelOrigin : point, scaledSize: new google.maps.Size(28,28) }
    }

    GetGMapAreaPolygon(): google.maps.Polygon {
        return new google.maps.Polygon({
            paths: this.GetSquarePositions(this.mapPositionObj),
            strokeColor: "#6666FF",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#6666FF",
            fillOpacity: 0.35
        });
    }

    GetSquarePositions(latlng: google.maps.LatLngLiteral) : google.maps.LatLngLiteral[] {
        let octagonPositions : google.maps.LatLngLiteral[] = [];

        octagonPositions.push({lat: latlng.lat + DELTA_MED, lng: latlng.lng + DELTA_MED})
        octagonPositions.push({lat: latlng.lat - DELTA_MED, lng: latlng.lng + DELTA_MED})
        octagonPositions.push({lat: latlng.lat - DELTA_MED, lng: latlng.lng - DELTA_MED})
        octagonPositions.push({lat: latlng.lat + DELTA_MED, lng: latlng.lng - DELTA_MED})
        octagonPositions.push({lat: latlng.lat + DELTA_MED, lng: latlng.lng + DELTA_MED})

        return octagonPositions;
    }

    //#endregion
}
