import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject } from 'rxjs';
import { Category } from '../models/category';

const TABLE = "categories";

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  private allCategories: Category[] = [];
  private categoriesProvider = new BehaviorSubject<Category[]>([]);
  public $categories = this.categoriesProvider.asObservable();

  constructor(private db: AngularFirestore) {
    this.fetchCategoriesData();
   }

   public fetchCategoriesData() {
    return this.db.collection(TABLE).valueChanges({ idField: 'id' }).subscribe((data: any[]) => {
          
          let x = data.map(({id, name, desc, iconUrl}) => new Category(id, name, desc, iconUrl))
               this.allCategories = x;
               this.categoriesProvider.next(this.allCategories);
               
       })
  }

  public getCategoryById(categoryId: number) {
    return this.categoriesProvider.value.find(category => category.id == categoryId);
  }

  public getCategoryByName(categoryName: string) {
    return this.categoriesProvider.value.find(category => category.name.toLowerCase() == categoryName.toLowerCase());
  }

}
