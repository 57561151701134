
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { BehaviorSubject, of } from 'rxjs';

import { Mirador } from '../models/mirador';
import { CategoriesService } from './categories.service';
import { MiradorFilter } from '../models/mirador-filter';
import { Location } from '@angular/common';
import { LOCATIONS } from '../enums & constants/filters.constants';

import { Router } from '@angular/router';
import { FilterChip } from '../enums & constants/filter-chips.constants';
import { EXPLORE_CUSTOM_LISTS } from '../enums & constants/list.constants';
import { MiradorCustomList } from '../models/mirador-custom-list';
import { MiradorMinimal } from '../models/mirador-minimal';

const TABLE = "miradores";

export interface ListPanelStatus {
  isOpen: boolean,
  filterApplied: any
}

export const LIST_PANEL_CLOSE: ListPanelStatus = { isOpen: false, filterApplied: null }
export const LIST_PANEL_OPEN: ListPanelStatus = { isOpen: true, filterApplied: null }

@Injectable({
  providedIn: 'root'
})
export class MiradoresService {

  private allMiradores: Mirador[] = [];
  private miradoresProvider = new BehaviorSubject<Mirador[]>([]);
  public $miradores = this.miradoresProvider.asObservable();

  private miradoresFiltered = new BehaviorSubject<Mirador[]>([]);
  public $miradoresFiltered = this.miradoresFiltered.asObservable();

  private miradoresLoaded = new BehaviorSubject<boolean>(false);
  public $miradoresLoaded = this.miradoresLoaded.asObservable();

  private miradorSelected = new BehaviorSubject<Mirador | null>(null);
  public $miradorSelected = this.miradorSelected.asObservable();

  private onListPanelStatusChange = new BehaviorSubject<ListPanelStatus>(LIST_PANEL_CLOSE);
  public $onListPanelStatusChange = this.onListPanelStatusChange.asObservable();

  private onFilterChangeByUrl = new BehaviorSubject<MiradorFilter>(null);
  public $onFilterChangeByUrl = this.onFilterChangeByUrl.asObservable();

  private currentChipSelected = new BehaviorSubject<FilterChip>(null);
  public $currentChipSelected = this.currentChipSelected.asObservable();

  private filters = new BehaviorSubject<MiradorFilter[]>([]);

  constructor(private categoriesService: CategoriesService,
    private db: AngularFirestore,
    private locationService: Location,
    private router: Router) {
    this.fetchMiradoresData();
    this.filterMiradores();
  }

  public fetchMiradoresData() {


    return this.db.collection(TABLE, ref => ref
      .orderBy('categoryId', 'asc')
      .orderBy("score", 'desc'))
    .valueChanges({ idField: 'miradorId' }).subscribe((data: any[]) => {
      let dataFiltered = data.filter((item) => {

        if (!item.hasOwnProperty('businessHours')) {
          return false;
        }

        if (item.hasOwnProperty('active')) {
          return item.active;
        }
        return true;
      });
      let x = dataFiltered.map((miradorData) =>
        new Mirador(miradorData, this.categoriesService.getCategoryById(miradorData["categoryId"]))
      );
      this.allMiradores = x;
      this.miradoresProvider.next(this.allMiradores);


      let dataChatGPT = dataFiltered.map((miradorData) =>
        new MiradorMinimal(miradorData, this.categoriesService.getCategoryById(miradorData["categoryId"]))
      );

      this.miradoresLoaded.next(true);
    })
  }

  public setMiradorSelected(selected: Mirador) {
    this.miradorSelected.next(selected);
  }

  public getMiradorByNormalizedURL(urlMiradorValue: string): Mirador {
    return this.allMiradores.find(mirador => {
      return this.getNormalizedMiradorNameForURL(mirador.name) == urlMiradorValue;
    })
  }

  public updateFilterByUrl(filter: MiradorFilter) {
    this.listPanelStatus({ isOpen: true, filterApplied: filter });
    this.addFilter(filter);

  }

  public addFilter(filter: MiradorFilter | MiradorFilter[]) {

    // let filterIdx = this.filters.findIndex((i) => i.type == filter.type);

    // if(filterIdx == -1) {
    //   this.filters.push(filter);
    // }else {
    //   this.filters.splice(filterIdx, 1, filter)
    // }

    if (Array.isArray(filter)) {
      this.filters.next(filter);
    } else if (!Array.isArray(filter)) {

      if (filter.value == 'Todos') {
        filter.value = '';
      }

      //this.listPanelStatus(LIST_PANEL_OPEN); review behaviour
      this.filters.next([filter]);
    }

  }

  public listPanelStatus(status: ListPanelStatus) { // review current behaviour, do we need that method
    if (this.onListPanelStatusChange.value.isOpen != (status.isOpen)) {
      this.onListPanelStatusChange.next(status);
      if (status.isOpen) {
        this.router.navigateByUrl('list/s');
      } else {
        this.router.navigateByUrl('/');
      }
      if (status.filterApplied) {
        this.onFilterChangeByUrl.next(status.filterApplied);
      }
    }
  }


  public filterMiradores() {
    this.filters.subscribe(newFilters => {
      if (this.allMiradores.length > 0) {
        let miradoresFiltered = this.allMiradores.filter((mirador) => {
          return this.matchAnyFilter(mirador, newFilters);
        })
        this.miradoresFiltered.next(miradoresFiltered);
      }
    })
  }

  public getMiradoresByFilters(newFilters) {
    if (this.allMiradores.length > 0) {
      let miradoresFiltered = this.allMiradores.filter((mirador) => {
        return this.matchAnyFilter(mirador, newFilters);
      })
      return miradoresFiltered;
    }
  }

  public getMiradoresByIds(miradoresIds: object[]) {
    if (this.allMiradores.length > 0) {
      let miradoresFiltered = this.allMiradores.filter((mirador) => {
        return miradoresIds.some((m: any) => m.id === mirador.miradorId)
      })
      return miradoresFiltered;
    }
  }

  public getListByListId(listId) : MiradorCustomList {
    let lists = EXPLORE_CUSTOM_LISTS;

    return lists.find(list => list.id == listId);
  }

 public filterByIds(miradoresIds: object[]) {
  let miradoresFound = this.getMiradoresByIds(miradoresIds);
  if(miradoresFound && miradoresFound.length){
    this.miradoresFiltered.next(miradoresFound)
  }
 }


  private matchAnyFilter(mirador: Mirador, miradorFilters: MiradorFilter[]): boolean {
    let isMatched = false;

    for (const element of miradorFilters) {
      let evalProp = this.getPropertyValueWithBracketsNotation(mirador, element.type) as string;
      if (evalProp) {
        if (Array.isArray(evalProp)) {
          evalProp = evalProp.join();
        }
        if (this.getNormalizedMiradorNameForURL(evalProp).includes(this.getNormalizedMiradorNameForURL(element.value))) {
          isMatched = true;
          break;
        }
      }
    }
    return isMatched;
  }




  public selectFirstMirador() {
    this.setMiradorSelected(this.allMiradores[0]);
  }

  public getZoneByName(zoneName: string) {
    let locations = LOCATIONS;

    return locations.find(location => location.value.toLowerCase() == zoneName.toLowerCase());
  }

  public SetCurrentFilterChipSelected(chip: FilterChip) {
    if(chip == null) {
      this.currentChipSelected.next(null);
    }

    if(chip != this.currentChipSelected.value) {
      if(this.currentChipSelected.value){
        this.currentChipSelected.value.selected = false;
      }
    } else {
      return;
    }

    chip.selected = !chip.selected;
    this.currentChipSelected.next(chip);
  }

  public resetCurrentChipSelected(){
    if(this.currentChipSelected.value){
      this.currentChipSelected.value.selected = false;
    }

    this.currentChipSelected.next(null);
  }

  defaultFilterChip(chipDefault: FilterChip){
    if(this.currentChipSelected.value == null) {
      this.SetCurrentFilterChipSelected(chipDefault);
      this.addFilter(chipDefault.filters);
    }
  }

  getNameByID(id: string) {
    const miradorEncontrado = this.allMiradores.find(mirador => mirador.miradorId === id);
    if (miradorEncontrado) {
      return miradorEncontrado.name;
    } else {
      return null; // Si no se encuentra el mirador con el ID dado
    }
  }

  //#region utils

  public getNormalizedMiradorNameForURL(value: string) {
    return this.removeAccents(value.trim().toLowerCase().replace(/ /g, "."));
  }

  public removeAccents(value: string) {
    return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  public getPropertyValueWithBracketsNotation(object: any, property: string) {
    if (!property.includes('.')) {
      return object[property];
    }

    let splitProperty = property.split('.', 2);

    return this.getPropertyValueWithBracketsNotation(object[splitProperty[0]], splitProperty[1]);
  }

  public setUrlPath(path: string) {
    this.locationService.replaceState("/" + path);
  }

  public getMiradorById(miradorId: string): Mirador | null {
    return this.allMiradores.find(mirador => mirador.miradorId === miradorId) || null;
  }


  /* return this.db.collection(TABLE, ref => ref
    .orderBy('categoryId', 'asc')
    .orderBy("score", 'desc'))
  .valueChanges({ idField: 'miradorId' }).subscribe((data: any[]) => { */



  //#endregion
}
