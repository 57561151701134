import { NgModule } from '@angular/core';
import { RangeDaysPipe } from './range-days.pipe';
import { MomentToDatePipe } from './moment-to-date.pipe';
import { SafevideoPipe } from './safevideo.pipe';
import { MMTranslatePipe } from './mmtranslate.pipe';
import { NumberWithDotPipe } from './number-with-dot.pipe';

@NgModule({
    declarations: [
    RangeDaysPipe,
    MomentToDatePipe,
    SafevideoPipe,
    MMTranslatePipe,
    NumberWithDotPipe
  ],
    imports: [],
    providers: [MMTranslatePipe],
    exports: [RangeDaysPipe, MomentToDatePipe, SafevideoPipe, MMTranslatePipe, NumberWithDotPipe]
})
export class PipesModule { }