import { NgModule } from '@angular/core';
import {
  PreloadAllModules,
  NoPreloading,
  RouterModule,
  Routes,
} from '@angular/router';
import { AuthenticateGuard } from './guards/authenticate.guard';
import { RankingAdventurersComponent } from './components/posts/ranking-adventurers/ranking-adventurers.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'app/explore-offers',
    pathMatch: 'full',
  },
  {
    path: 'app',
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'saved',
    canActivate: [AuthenticateGuard],
    loadChildren: () =>
      import('./pages/miradores-fav/miradores-fav.module').then(
        (m) => m.MiradoresFavPageModule
      ),
  },
  {
    path: 'mirador',
    loadChildren: () =>
      import('./pages/mirador-details/mirador-details.module').then(
        (m) => m.MiradorDetailsPageModule
      ),
  },
  {
    path: 'settings',
    canActivate: [AuthenticateGuard],
    loadChildren: () =>
      import('./pages/settings/settings.module').then(
        (m) => m.SettingsPageModule
      ),
  },
  {
    path: 'guide',
    loadChildren: () =>
      import('./pages/guide/guide.module').then((m) => m.GuidePageModule),
  },
  {
    path: 'feedback',
    loadChildren: () =>
      import('./pages/feedback/feedback.module').then(
        (m) => m.FeedbackPageModule
      ),
  },
  {
    path: 'marketing',
    loadChildren: () =>
      import('./pages/marketing/marketing.module').then(
        (m) => m.MarketingPageModule
      ),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'list/:listId',
    loadChildren: () =>
      import('./pages/list-detail/list-detail.module').then(
        (m) => m.ListDetailPageModule
      ),
  },
  {
    path: 'record-visit',
    canActivate: [AuthenticateGuard],
    loadChildren: () =>
      import('./pages/record-visit/record-visit.module').then(
        (m) => m.RecordVisitPageModule
      ),
  },
  {
    path: 'else-profile/:uid',
    canActivate: [],
    loadChildren: () =>
      import('./pages/else-profile/else-profile.module').then(
        (m) => m.ElseProfilePageModule
      ),
  },
  {
    path: 'verify-code/:phone',
    loadChildren: () =>
      import('./pages/verify-code/verify-code.module').then(
        (m) => m.VerifyCodePageModule
      ),
  },
  {
    path: 'chat-bot',
    canActivate: [AuthenticateGuard],
    loadChildren: () =>
      import('./pages/chat-bot/chat-bot.module').then(
        (m) => m.ChatBotPageModule
      ),
  },
  {
    path: 'photo-rejected/:imageUrl',
    loadChildren: () =>
      import('./pages/photo-rejected/photo-rejected.module').then(
        (m) => m.PhotoRejectedPageModule
      ),
  },
  {
    path: 'experience',
    loadChildren: () =>
      import('./pages/experience-detail/experience-detail.module').then(
        (m) => m.ExperienceDetailPageModule
      ),
  },
  {
    path: 'reserves',
    loadChildren: () =>
      import('./pages/reserves/reserves.module').then(
        (m) => m.ReservesPageModule
      ),
  },
  {
    path: 'reserve',
    loadChildren: () =>
      import('./pages/reserve-detail/reserve-detail.module').then(
        m => m.ReserveDetailPageModule
      )
  },
  {
    path: 'admin-reserves',
    loadChildren: () => import('./pages/admin-reserves/admin-reserves.module').then(m => m.AdminReservesPageModule)
  },
  {
    path: 'admin-reservation',
    loadChildren: () => import('./pages/admin-reservation-detail/admin-reservation-detail.module').then(m => m.AdminReservationDetailPageModule)
  },
  {
    path: 'affiliates',
    loadChildren: () => import('./pages/affiliates/affiliates.module').then(m => m.AffiliatesPageModule)
  },
  {
    path: 'affiliate-enrollment',
    loadChildren: () => import('./pages/affiliate-enrollment/affiliate-enrollment.module').then(m => m.AffiliateEnrollmentPageModule)
  },
  {
    path: 'affiliate-overview/:affiliateId',
    loadChildren: () => import('./pages/affiliate-overview/affiliate-overview.module').then(m => m.AffiliateOverviewPageModule)
  },
  {
    path: 'ranking-adventurers',
    component: RankingAdventurersComponent,
    loadChildren: () => import('./components/posts/posts.module').then(m => m.PostCardModule)
  },
  {
    path: 'see-all-rankings',
    loadChildren: () => import('./pages/see-all-rankings/see-all-rankings.module').then(m => m.SeeAllRankingsPageModule)
  },
  {
    path: 'album-detail/:albumId',
    loadChildren: () => import('./pages/album-detail/album-detail.module').then(m => m.AlbumDetailPageModule)
  },
  {
    path: 'search-list',
    loadChildren: () =>
      import('./pages/search-list/search-list.module').then(
        (m) => m.SearchListPageModule
      ),
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then(m => m.SearchPageModule)
  },
  {
    path: 'list-experiences/:listId',
    loadChildren: () =>
      import('./pages/list-detail-experiences/list-detail-experiences.module').then(
        m => m.ListDetailExperiencesPageModule
      ),
  },
  {
    path: 'explorar',
    loadChildren: () => import('./pages/explorar/explorar.module').then( m => m.ExplorarPageModule)
  },
  {
    path: 'events',
    loadChildren: () => import('./pages/events/events.module').then( m => m.EventsPageModule)
  },
  {
    path: 'event',
    loadChildren: () =>
      import('./pages/event-detail/event-detail.module').then(
        (m) => m.EventDetailPageModule
      ),
  },
  {
    path: 'membership',
    loadChildren: () => import('./pages/membership/membership.module').then( m => m.MembershipPageModule)
  },
  {
    path: 'event-payment',
    loadChildren: () => 
      import('./pages/event-payment-detail/event-payment-detail.module').then( m => m.EventPaymentDetailPageModule)
  },
  {
    path: 'all-experiences',
    loadChildren: () => import('./pages/explora/explora.module').then( m => m.ExploraPageModule)
  },
  {
    path: 'miradores-city',
    loadChildren: () => import('./pages/miradores-city/miradores-city.module').then( m => m.MiradoresCityPageModule)
  },
  {
    path: 'explore-offers',
    loadChildren: () => import('./pages/explore-offers/explore-offers.module').then( m => m.ExploreOffersPageModule)
  },










];
@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
