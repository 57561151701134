export const citiesAndRegions = [
    {
      geolocation: { lat: 6.2442, lng: -75.5812 }, // Geolocalización de Medellín
      zoom_map: 10,
      country: 'Colombia',
      id: 'medellin',
      mainCityRegion: 'Medellín',
      album_id:'WVhrN3vzHoJh771yDtne',
    },
    {
      geolocation: { lat: 4.624335, lng: -75.69062 }, // Geolocalización del Eje Cafetero
      zoom_map: 9,
      country: 'Colombia',
      id: 'eje_cafetero',
      mainCityRegion: 'Eje Cafetero',
      album_id:'eDIWMMIIJ9DqMLfo6Jjt',
    },
    {
      geolocation: { lat: 4.7109886, lng: -74.072092 }, // Geolocalización de Bogotá
      zoom_map: 10,
      country: 'Colombia',
      id: 'bogota',
      mainCityRegion: 'Bogotá',
      album_id:'vjqoQHytaTvJJyg37yP1',
    },
    {
      geolocation: { lat: 10.391, lng: -75.4794 }, // Geolocalización de Cartagena
      zoom_map: 10,
      country: 'Colombia',
      id: 'cartagena',
      mainCityRegion: 'Cartagena',
      album_id:'TuZYI3XFySXwBHy8HxEu',
    },
    {
      geolocation: { lat: 10.391, lng: -75.4794 }, // Geolocalización de Cartagena
      zoom_map: 10,
      country: 'Republica Dominicana',
      id: 'republica_dominicana',
      mainCityRegion: 'Republica Dominicana',
      album_id:'F4FC69IzsQaoIplrroAv',
    },
    {
      geolocation: { lat: 10.391, lng: -75.4794 }, // Geolocalización de Cartagena
      zoom_map: 10,
      country: 'Costa Rica',
      id: 'costa_rica',
      mainCityRegion: 'Costa Rica',
      album_id:'ilKYiERszUdcDeNfkujl',
    }
  ];