import { FilterData } from '../components/filter-button/filter.interfaces';

export const LOCATIONS : FilterData[] = [
  { label: 'Todas las zonas', value: 'Todos' },
  { label: 'Suroriente', value: 'Suroriente' },
  { label: 'Suroccidente', value: 'Suroccidente' },
  { label: 'Noroccidente', value: 'Noroccidente' },
  { label: 'Nororiente', value: 'Nororiente' },
  { label: 'Ctr de Medellin', value: 'Centro' },
];

export const DISCONNECTIONS : FilterData[] = [
  { label: 'Todos', value: 'Todos' },
  { label: 'Aventura', value: 'Aventura' },
  { label: 'Diversión', value: 'Diversión' },
  { label: 'Tranquilidad', value: 'Tranquilidad' },
  { label: 'Relax', value: 'Relax' },
];

export const CATEGORIES: FilterData[] = [
  { label: 'Todas las categorias', value: 'Todos' },
  { label: 'Est Público', value: 'Publico' },
  { label: 'Est Comercial', value: 'Comercial' },
  { label: 'Rooftop (Azotea)', value: 'Rooftop' },
  { label: 'Mall', value: 'Mall' },
  { label: 'Hospedaje', value: 'Hospedaje' }

];
