import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Post } from 'src/app/models/post';
import { RankingItem } from 'src/app/models/raking';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { HomeService } from 'src/app/services/home.service';
import { UsersManagerService } from 'src/app/services/users-manager.service';
import { LoadingController } from '@ionic/angular';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-ranking-adventurers',
  templateUrl: './ranking-adventurers.component.html',
  styleUrls: ['./ranking-adventurers.component.scss'],
})
export class RankingAdventurersComponent implements OnInit, OnDestroy  {
  @Input() post: Post;
  adventurers: RankingItem[] = [];
  users: User[] = [];
  currentSesion: User;
  activeAllRanking = false;
  loading;
  currentUserPosition: RankingItem;
  titleRanking: string
  private unsubscribe$ = new Subject<void>();


  constructor(
    private userService: UsersManagerService,
    public auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private homeService: HomeService,
    private loadingController: LoadingController,

  ) {}

  ngOnInit() {
    this.getSesion();

    this.route.paramMap
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((params) => {
      const active = params.get('activeAllRanking') || null;
      const postId = params.get('postId') || null;
      this.activeAllRanking = active === 'true';

      if(!this.activeAllRanking){
        this.getRankings(this.post);
        this.getCurrentUserPosition();
        this.getTitleRanking(this.post)
      }else{
        this.presentLoading()
        this.homeService.getPostsRankings()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(posts=>{
          if (posts) {
            const filteredPosts = posts.filter(post => post.postId === postId);
            this.getTitleRanking(filteredPosts[0])
            this.getRankings(filteredPosts[0]);
            this.closeLoading()
          }
        });

      }
    });
  }

  getTitleRanking(post: Post){
    if (post && post.content && post.content.type === 'Top Aventureros del Mes') {
      const month = post.content.period.split(' ')[0];
      // Convertimos la primera letra del mes a mayúscula y el resto a minúscula
      const formattedMonth = month.charAt(0).toUpperCase() + month.slice(1).toLowerCase();
      this.titleRanking = `Ranking Top aventureros del Mes ${formattedMonth}`;
    } else {
      this.titleRanking = 'Ranking Top aventureros a lo largo del tiempo';
    }
  }

  getSesion() {
    this.userService.$allUsers
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((data) => {
      this.users = data;
    });

    this.auth.$establishedSession
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((user) => {
      this.currentSesion = user;
    });
  }

  getCurrentUserPosition() {
    if (this.currentSesion && this.post) {
      const adventurer = this.post.content.items.find(
        (adventurer) => adventurer.userId === this.currentSesion.uid
      );

      if (adventurer) {
        const index = this.post.content.items.indexOf(adventurer);
        this.currentUserPosition = {
          ...adventurer,
          position: index + 1,
          displayName: this.currentSesion?.displayName || '',
          photoURL: this.currentSesion?.photoURL || '',
          emailVerified: this.currentSesion?.emailVerified || false,
          instagram: this.currentSesion?.instagram || '',
          phoneNumber: this.currentSesion?.phoneNumber || '',
          profileStatus: this.currentSesion?.profileStatus || 'notCompleted',
          extendedRole: this.currentSesion?.extendedRole || '',
        };
      }
    }
  }

  getRankingClass(index: number): string {
    if (index === 0) {
      return 'gold';
    } else if (index === 1) {
      return 'silver';
    } else if (index === 2) {
      return 'bronze';
    } else {
      return '';
    }
  }

  goToElseProfile(userId: string) {
    if (this.currentSesion && userId === this.currentSesion.uid) {
      this.router.navigate(['app/profile']);
    } else {
      this.router.navigate(['else-profile', userId]);
    }
  }

  getRankings(post: Post) {
    this.adventurers = post?.content.items.map((adventurerData) => {
      const user = this.users.find((u) => u.uid === adventurerData.userId);

      return {
        userId: adventurerData.userId,
        position: adventurerData.position,
        miradorCount: adventurerData.miradorCount,
        userCreationDate: adventurerData.userCreationDate,
        displayName: user?.displayName || '',
        photoURL: user?.photoURL || '',
        emailVerified: user?.emailVerified || false,
        instagram: user?.instagram || '',
        phoneNumber: user?.phoneNumber || '',
        profileStatus: user?.profileStatus || 'notCompleted',
        extendedRole: user?.extendedRole || '',
      };
    });
  }

  viewMore() {
    this.router.navigate([
      'ranking-adventurers',
      { activeAllRanking: 'true', postId: this.post.postId },
    ]);
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      duration: 700,
      translucent: true,
      backdropDismiss: false,
    });
    await this.loading.present();
  }

  closeLoading() {
    if (this.loading) {
      this.loading.dismiss();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
