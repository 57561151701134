import { MedalEarned } from "./medal";

export class User {
    uid: string;
    email: string;
    displayName: string;
    photoURL: string;
    emailVerified: boolean;
    instagram?: string;
    phoneNumber?: string;
    profileStatus?: 'notCompleted' | 'updatedByUser' | 'updatedByFacebook';
    extendedRole?: string;
    medalsObtained?: MedalEarned[];
    albums?: Album[];
    premium?: boolean;
    subscriptionId?: string;
    subscriptions?: any[]; // Agregar el campo de suscripción

    constructor(userData: any) {
        this.uid = userData.uid;
        this.email = userData.email;
        this.displayName =userData.displayName;
        this.photoURL= userData.photoURL;
        this.emailVerified= userData.emailVerified;
        this.instagram= userData.instagram;
        this.phoneNumber= userData.phoneNumber;
        this.profileStatus= userData.profileStatus;
        this.extendedRole=userData.extendedRole;
        this.medalsObtained= userData.medalsObtained;
        this.albums = userData.albums || [];
        this.premium= userData.premium;
        this.subscriptionId= userData.subscriptionId;
    }
}

export interface Album {
    albumId: string;
    invitationsCompleted: number;
  }