import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rangeDays'
})
export class RangeDaysPipe implements PipeTransform {

  transform(value: string, ...args: number[]): string {
    let result : string;
    let daysHours = value.split(' | ');
    let days = daysHours[0].split(' ');
    if (args[0] == 3) {
    switch(days.length) {
      case 1:
        result = days[0];
        break;
      case 2:
        result = days.join(" y ");
        break;
      default: 
        result = `${days[0]} a ${days[days.length-1]}`;
      }
    } else {
      result = value;
    }

    return result;
  }

}
