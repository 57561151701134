import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { User } from '../models/user';
import { finalize } from 'rxjs/operators';
import { Visit } from '../models/visit';
import { MiradorSaved } from '../models/mirador-save';
import { MiradorLiked } from '../models/mirador-like';
import { UserToMigrate } from '../models/userToMigrate';
import { HttpClient } from '@angular/common/http';
import { Ng2ImgMaxService } from 'ng2-img-max';

const TABLE = "users";
const FIRESTORAGE_BASE_PATH = "profile-picture"


@Injectable({
  providedIn: 'root'
})
export class UsersManagerService {

  private usersProvider = new BehaviorSubject<User[]>([]);
  public $allUsers = this.usersProvider.asObservable();

  public usersLoaded = new BehaviorSubject<boolean>(false);
  public $usersLoaded = this.usersLoaded.asObservable();

  public getAllUsersSubscription : Subscription;


  constructor(public fireStore: AngularFirestore, public fireStorage: AngularFireStorage, public httpService: HttpClient, private ng2ImgMax: Ng2ImgMaxService) {
    this.syncAllUsers();
   }

   syncAllUsers() {
    this.usersLoaded.next(false);
    this.getAllUsersSubscription = this.getAllUsers().subscribe((data) => {
      if(data.length) {
        // this.showExtrangerPeople(data);
        this.usersProvider.next(data);
       this.usersLoaded.next(true);
       this.getAllUsersSubscription.unsubscribe();
      }
     })
   }


   showExtrangerPeople(data) {
    const mappedData = data.map(user => ({ displayName: user.displayName, phoneNumber: user.phoneNumber }));
    // Filtrar los números de teléfono que no empiezan por '57' o '+57'
    const filteredData = mappedData.filter(user => !user.phoneNumber.startsWith('57') && !user.phoneNumber.startsWith('+57'));
    console.log({filteredData});
    console.log(JSON.stringify(filteredData));
   }


  getAllUsers() : Observable<(User)[]> {
      return this.fireStore.collection<User>(TABLE)
      .valueChanges();
  }

  getUserByUID(uid: string) {
    let userData  = this.usersProvider.getValue().find(u => u.uid === uid);
    let user = new User(userData);
    return user;
  }

  SetInstagram(user: User, instagramUser: string) : Promise<void> {
    const saveRef: AngularFirestoreDocument<any> = this.fireStore.doc(
      `${TABLE}/${user.uid}`
    );

    return  saveRef.update({ instagram: instagramUser }).then(() => {  });

  }

  SetDataAccount(user: User, nameUser: string, instagramUser: string, photoURL: string) : Promise<void> {
    const saveRef: AngularFirestoreDocument<any> = this.fireStore.doc(
      `${TABLE}/${user.uid}`
    );

    return  saveRef.update({ instagram: instagramUser, displayName: nameUser,photoURL, profileStatus: 'updatedByUser' }).then(() => {  });

  }

  uploadImageProfile(file: any, userId: string, filename: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const cleanedBase64 = file.replace(/^data:image\/\w+;base64,/, '');
      const blob = this.base64ToBlob(cleanedBase64, 'image/png');
      const fileObject = new File([blob], filename, { type: 'image/png' });

      // Comprime la imagen usando Ng2ImgMaxService 500kb max
      this.ng2ImgMax.compressImage(fileObject, 0.5, true).subscribe({
        next: (result) => {
          const compressedFile = new File([result], filename, { type: 'image/png' });
          const filePath = `${FIRESTORAGE_BASE_PATH}/${userId}/${filename}`;
          const ref = this.fireStorage.ref(filePath);
          const task = ref.put(compressedFile);

          task.snapshotChanges().pipe(
            finalize(() => {
              ref.getDownloadURL().subscribe({
                next: (url) => resolve(url),
                error: (getUrlError) => reject(getUrlError),
              });
            })
          ).subscribe();
        },
        error: (compressionError) => {
          console.error('Error al procesar la imagen:', compressionError);
          reject(compressionError);
        },
      });
    });
  }

   base64ToBlob(base64: string, type: string): Blob {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: type });
  }


  deleteAccount (allDataUserToDelete: UserToMigrate) {

    console.log(allDataUserToDelete);

    allDataUserToDelete.likes.forEach((l) => {
      const likeRef: AngularFirestoreDocument<any> = this.fireStore.doc(
        `likes/${l.likeId}`
      );
      return likeRef.delete().then(() => {
        console.log('Like successfully deleted!');
      }).catch((error) => {
        console.error('Error removing like: ', error);
      });
    })


    allDataUserToDelete.visits.forEach((v) => {
      const visitRef: AngularFirestoreDocument<any> = this.fireStore.doc(
        `visits/${v.visitId}`
      );
      return visitRef.delete().then(() => {
        console.log('visit successfully deleted!');
      }).catch((error) => {
        console.error('Error removing visit: ', error);
      });
    })

    allDataUserToDelete.saves.forEach((s) => {
      const savedRef: AngularFirestoreDocument<any> = this.fireStore.doc(
        `saves/${s.saveId}`
      );
      return savedRef.delete().then(() => {
        console.log('save successfully deleted!');
      }).catch((error) => {
        console.error('Error removing save: ', error);
      });
    })


    this.fireStore.collection('users').doc(allDataUserToDelete.uid).delete().then(() => {
      console.log('User successfully deleted in firestore!');
    }).catch((error) => {
      console.error('Error removing user: ', error);
    });

    this.deleteAccountAutentications(allDataUserToDelete.uid);
  }

  deleteAccountAutentications(uid: string) {
    const url = `https://us-central1-miradores-ac574.cloudfunctions.net/api/user/delete-user?uid=${uid}`;
    this.httpService.delete(url).subscribe(
      response => {
        console.log('User successfully deleted in autententication!', response);
      },
      error => {
        console.error('Error deleting user: ', error);
      }
    );
  }




}
