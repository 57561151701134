// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.install-banner {
  background-color: rgba(0, 0, 0, 0.5); /* Fondo negro con 80% de opacidad */
  color: #fff;
  padding: 10px;
  text-align: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.install-banner ion-item {
  background: rgba(255, 255, 255, 0.2); /* Fondo blanco con 20% de opacidad */
  border-radius: 8px; /* Opcional: para redondear los bordes */
  margin-bottom: 10px; /* Espaciado entre los elementos */
}
.install-banner ion-avatar img {
  opacity: 0.9; /* Imagen con 90% de opacidad */
}
.install-banner ion-label h3, .install-banner ion-label p {
  opacity: 0.9; /* Texto con 90% de opacidad */
}
.install-banner ion-button {
  opacity: 0.9; /* Botón con 90% de opacidad */
}
.install-banner ion-icon {
  opacity: 0.9; /* Iconos con 90% de opacidad */
}

.install-banner a {
  color: #00ff00;
  font-weight: bold;
  text-decoration: none;
}

.install-banner button {
  background: none;
  border: none;
  color: #fff;
  font-size: 16px;
  margin-left: 20px;
}

ion-avatar {
  --border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/install-banner/install-banner.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,oCAAA,EAAA,oCAAA;EACA,WAAA;EACA,aAAA;EACA,kBAAA;EACA,eAAA;EACA,MAAA;EACA,WAAA;EACA,aAAA;AAEF;AAAE;EACE,oCAAA,EAAA,qCAAA;EACA,kBAAA,EAAA,wCAAA;EACA,mBAAA,EAAA,kCAAA;AAEJ;AACE;EACE,YAAA,EAAA,+BAAA;AACJ;AAEE;EACE,YAAA,EAAA,8BAAA;AAAJ;AAGE;EACE,YAAA,EAAA,8BAAA;AADJ;AAIE;EACE,YAAA,EAAA,+BAAA;AAFJ;;AAME;EACE,cAAA;EACA,iBAAA;EACA,qBAAA;AAHJ;;AAME;EACE,gBAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;EACA,iBAAA;AAHJ;;AAME;EACE,oBAAA;AAHJ","sourcesContent":[".install-banner {\r\n  background-color: rgba(0, 0, 0, 0.5); /* Fondo negro con 80% de opacidad */\r\n  color: #fff;\r\n  padding: 10px;\r\n  text-align: center;\r\n  position: fixed;\r\n  top: 0;\r\n  width: 100%;\r\n  z-index: 1000;\r\n\r\n  ion-item {\r\n    background: rgba(255, 255, 255, 0.2); /* Fondo blanco con 20% de opacidad */\r\n    border-radius: 8px; /* Opcional: para redondear los bordes */\r\n    margin-bottom: 10px; /* Espaciado entre los elementos */\r\n  }\r\n\r\n  ion-avatar img {\r\n    opacity: 0.9; /* Imagen con 90% de opacidad */\r\n  }\r\n\r\n  ion-label h3, ion-label p {\r\n    opacity: 0.9; /* Texto con 90% de opacidad */\r\n  }\r\n\r\n  ion-button {\r\n    opacity: 0.9; /* Botón con 90% de opacidad */\r\n  }\r\n\r\n  ion-icon {\r\n    opacity: 0.9; /* Iconos con 90% de opacidad */\r\n  }\r\n}\r\n  \r\n  .install-banner a {\r\n    color: #00ff00;\r\n    font-weight: bold;\r\n    text-decoration: none;\r\n  }\r\n  \r\n  .install-banner button {\r\n    background: none;\r\n    border: none;\r\n    color: #fff;\r\n    font-size: 16px;\r\n    margin-left: 20px;\r\n  }\r\n\r\n  ion-avatar {\r\n    --border-radius: 4px;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
