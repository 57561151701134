import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'MMTranslate'
})
export class MMTranslatePipe implements PipeTransform {

  transform(value: string, obj: any, lang: string, ...args: unknown[]): any {
    if(!obj) {
      return null;
    }
    
    if(lang == 'en') {
      return obj['en'][value];
    }else {
      return obj[value];
    }
  }

}
