import { MiradorCustomList } from "../models/mirador-custom-list";
import { MiradorList } from "../models/mirador-list";

export const EXPLORE_LISTS: MiradorList[] = [


    //Categorias
    { id: 'publicos', name: 'Miradores Públicos', description: "", filters: [{ type: "category.name", value: "Publico" }] },
    { id: 'comerciales', name: 'Miradores Comerciales', description: "", filters: [{ type: "category.name", value: "Comercial" }] },
    { id: 'rooftops', name: 'Rooftops', description: "", filters: [{ type: "category.name", value: "Rooftop" }] },
    { id: 'malls', name: 'Malls miradores', description: "", filters: [{ type: "category.name", value: "Mall" }] },
    { id: 'hospedajes', name: 'Hospedajes y glamping con mirador', description: "", filters: [{ type: "category.name", value: "Hospedaje" }] },



    //## TAGS ##

    // Tipos de desconexion
    { id: 'diversión', name: 'Diversion', description: "", filters: [{ type: "tags", value: "diversión" }] },
    { id: 'aventura', name: 'Aventura', description: "", filters: [{ type: "tags", value: "aventura" }] },
    { id: 'tranquilidad', name: 'Tranquilidad', description: "", filters: [{ type: "tags", value: "tranquilidad" }] },

    // Ambientes
    { id: 'familiar', name: 'Para ir con la familia', description: "", filters: [{ type: "tags", value: "familiar" }] },
    { id: 'romántico', name: 'Romántico', description: "Ambiente para compartir en pareja", filters: [{ type: "tags", value: "romántico" }] },
    { id: '4:20', name: '4:20', description: "", filters: [{ type: "tags", value: "4:20" }] },
    { id: 'urbano', name: 'Urbano', description: "", filters: [{ type: "tags", value: "urbano" }] },
    { id: 'instagrameable', name: 'Instagrameable', description: "Con lugares bonitos para fotos", filters: [{ type: "tags", value: "instagrameable" }] },
    { id: 'juvenil', name: 'Juvenil', description: "", filters: [{ type: "tags", value: "juvenil" }] },
    { id: 'naturaleza', name: 'Naturaleza', description: "", filters: [{ type: "tags", value: "naturaleza" }] },
    { id: 'exclusivo', name: 'Exclusivo', description: "Los miradores mas exclusivos de la ciudad", filters: [{ type: "tags", value: "exclusivo" }] },
    { id: 'sofisticado', name: 'sofisticado', description: "", filters: [{ type: "tags", value: "sofisticado" }] },
    { id: 'frio', name: 'Frio', description: "", filters: [{ type: "tags", value: "frio" }] },
    { id: 'deporte', name: 'Deporte', description: "", filters: [{ type: "tags", value: "deporte" }] },
    { id: 'tradicional', name: 'Tradicional', description: "", filters: [{ type: "tags", value: "tradicional" }] },
    { id: 'arte/cultura', name: 'Arte/Cultura', description: "", filters: [{ type: "tags", value: "arte/cultura" }] },
    { id: 'fiesta', name: 'Fiesta', description: "", filters: [{ type: "tags", value: "fiesta" }] },

    // Caracteristicas especificas
    { id: 'fogata', name: 'Fogata', description: "Estos son algunos miradores con fogata 🔥", filters: [{ type: "tags", value: "Fogata" }] },
    { id: 'bar', name: 'Bar', description: "", filters: [{ type: "tags", value: "bar" }] },
    { id: 'restaurante', name: 'Restaurante', description: "", filters: [{ type: "tags", value: "restaurante" }] },
    { id: 'gourmet', name: 'Gourmet', description: "Estos son algunos miradores con comida gourmet", filters: [{ type: "tags", value: "gourmet" }] },
    { id: 'coctelería', name: 'Coctelería', description: "", filters: [{ type: "tags", value: "coctelería" }] },
    { id: 'musica.en.vivo', name: 'Musica en Vivo', description: "", filters: [{ type: "tags", value: "musica en vivo" }] },
    { id: 'dj.en.vivo', name: 'Dj en Vivo', description: "", filters: [{ type: "tags", value: "dj en vivo" }] },
    { id: 'fonda', name: 'Fonda', description: "", filters: [{ type: "tags", value: "fonda" }] },
    { id: 'camping', name: 'Camping', description: "", filters: [{ type: "tags", value: "camping" }] },
    { id: 'comida.callejera', name: 'Comida Callejera', description: "", filters: [{ type: "tags", value: "comida callejera" }] },

    // Caracteristicas para tus salidas familiares
    { id: 'juegos.infantiles', name: 'Juegos Infantiles', description: "", filters: [{ type: "tags", value: "juegos infantiles" }] },
    { id: 'parrilla', name: 'Parrilla', description: "", filters: [{ type: "tags", value: "parrilla" }] },
    { id: 'heladería', name: 'Heladería', description: "", filters: [{ type: "tags", value: "heladería" }] },
    { id: 'alquiler.de.parrilla', name: 'Alquiler de Parrilla', description: "", filters: [{ type: "tags", value: "alquiler de parrilla" }] },
    { id: 'pet.friendly', name: 'Pet friendly', description: "", filters: [{ type: "tags", value: "pet friendly" }] },
    { id: 'granja', name: 'Granja', description: "", filters: [{ type: "tags", value: "granja" }] },

    // Caracteristicas especiales
    { id: 'eventos', name: 'Eventos', description: "", filters: [{ type: "tags", value: "eventos" }] },
    { id: 'vista.imponente', name: 'Vista Imponente', description: "", filters: [{ type: "tags", value: "vista imponente" }] },
    { id: 'pastizal', name: 'Pastizal', description: "", filters: [{ type: "tags", value: "pastizal" }] },
    { id: 'jacuzzi', name: 'Jacuzzi', description: "", filters: [{ type: "tags", value: "jacuzzi" }] },
    { id: 'chocolate.con.queso', name: 'Chocolate Con Queso', description: "", filters: [{ type: "tags", value: "chocolate con queso" }] },
    { id: 'actividades.extremas', name: 'Actividades Extremas', description: "", filters: [{ type: "tags", value: "actividades extremas" }] },
    { id: 'juegos.para.adultos', name: 'Juegos Para Adultos', description: "", filters: [{ type: "tags", value: "juegos para adultos" }] },
    { id: 'piscina', name: 'Piscina', description: "", filters: [{ type: "tags", value: "piscina" }] },


    /* EN DESUSO 👇 */

    // Listas de alborada
/*     { id: 'alborada-itagui', name: 'Alborada Itagui', description: "Miradores para ver la alborada en Itagui", filters: [{ type: "location", value: "itagui" }] },
    { id: 'alborada-san-felix', name: 'Alborada Via San Felix', description: "Miradores para ver la alborada en la via san felix", filters: [{ type: "sector", value: "San Félix" }] },
    { id: 'alborada-sabaneta', name: 'Alborada Sabaneta', description: "Miradores para ver la alborada en sabaneta", filters: [{ type: "nearbyNeighborhood", value: "Sabaneta" }] },
    { id: 'alborada-belen', name: 'Alborada Belén', description: "Miradores para ver la alborada en Belén", filters: [{ type: "nearbyNeighborhood", value: "Belén" }] },
    { id: 'alborada-robledo', name: 'Alborada Robledo', description: "Miradores para ver la alborada en Robledo", filters: [{ type: "nearbyNeighborhood", value: "Robledo" }] },
    { id: 'alborada-manrique', name: 'Alborada Manrique', description: "Miradores para ver la alborada en Manrique", filters: [{ type: "nearbyNeighborhood", value: "Manrique" }] },
 */

    // Categorias especiales
/*     { id: 'cerro.tutelar', name: 'Cerro Tutelar', description: "", filters: [{ type: "tags", value: "cerro tutelar" }] },
    { id: 'UVA', name: 'UVA', description: "Unidades de vida articulada", filters: [{ type: "tags", value: "UVA" }] },
    { id: 'cerro', name: 'Cerro', description: "", filters: [{ type: "tags", value: "cerro" }] },
 */
    // Sectores conocidos 
 /*    { id: 'san-felix', name: 'Via san Felix', description: "", filters: [{ type: "sector", value: "San Félix" }] },
    { id: 'belen', name: 'Sector belén', description: "", filters: [{ type: "sector", value: "Belén" }] },
    { id: 'sabaneta', name: 'En sabaneta', description: "", filters: [{ type: "sector", value: "Sabaneta" }] },
    { id: 'manrique', name: 'Manrique', description: "", filters: [{ type: "sector", value: "Manrique" }] },
    { id: 'robledo', name: 'Robledo', description: "", filters: [{ type: "sector", value: "Robledo" }] },
    { id: 'san-antonio-de-prado', name: 'San Antonio de Prado', description: "Miradores en San Antonio de Prado", filters: [{ type: "nearbyNeighborhood", value: "San Antonio De Prado" }] },
    { id: 'santa-elena', name: 'En santa elena', description: "", filters: [{ type: "locationData.zone", value: "Santa Elena" }] },
 */

    // Ciudad-municipio 
/*     { id: 'medellin', name: 'Medellín', description: "Miradores en Medellín", filters: [{ type: "location", value: "medellin" }] },
    { id: 'itagui', name: 'Itagüi', description: "Miradores en Itagüi", filters: [{ type: "location", value: "itagui" }] },
    { id: 'sabaneta', name: 'Sabaneta', description: "Miradores en Sabaneta", filters: [{ type: "location", value: "Sabaneta" }] },
    { id: 'copacabana', name: 'Copacabana', description: "Miradores en Copacabana", filters: [{ type: "location", value: "Copacabana" }] },
    { id: 'envigado', name: 'Envigado', description: "Miradores en Envigado", filters: [{ type: "location", value: "envigado" }] },
    { id: 'bello', name: 'Bello', description: "Miradores en Bello", filters: [{ type: "location", value: "bello" }] },
    { id: 'la-estrella', name: 'La Estrella', description: "Miradores La Estrella", filters: [{ type: "location", value: "la estrella" }] },
    { id: 'caldas', name: 'Caldas', description: "Miradores en Caldas", filters: [{ type: "location", value: "caldas" }] },
 */
];


export const EXPLORE_CUSTOM_LISTS: MiradorCustomList[] = [
    {
        id: 'pococomunes', name: 'Miradores poco conocidos', description: "", miradoresId: [
            { id: "tQNR4bji64gD8VkBGPQo", nombre: "Tipi" },
            { id: "BXN7AaLBfygdHkNXd4OP", nombre: "San ateo" },
            { id: "lMNhtm9PVQSRhT4RycP6", nombre: "La palma" },
            { id: "EWedMGKb6HxX3C6DqD9S", nombre: "Mirador de yeyo" },
            { id: "5UxhSzpRAurN4wqgEv3i", nombre: "Kilometro 1" },
            { id: "5Jcn6qDeZNt9JFqTm8A1", nombre: "El rancho" },
            { id: "xbhJ5bnh0FRc00vJali8", nombre: "La monnstaña que piensa" },
            { id: "LheE19V5RKwE2A8mjp6U", nombre: "Nakawe" },
            { id: "22SiQZlBKHj7nSN1qzSA", nombre: "Mirador de toñito" },
        ]
    },
    {
        id: 'recomendados', name: 'Recomendados miradores', description: "Seleccion especial del equipo de miradores 👌", miradoresId: [
            { id: "tVOyK1ZWbDfMvL4D7IMB", nombre: "La Bendición" },
            { id: "OSbvSNtTKoK5ewHuppPE", nombre: "La Torre Picnic" },
            { id: "BXN7AaLBfygdHkNXd4OP", nombre: "San Ateo" },
            { id: "V9ecEayUzYG35TxxA7VY", nombre: "Pachamama Ancestral" },
            { id: "qpPifnJxlwvZPUl3MjS8", nombre: "Michigan" },
            { id: "5ezZUlMUexX0WSWdsuin", nombre: "El encanto" },
            { id: "lMNhtm9PVQSRhT4RycP6", nombre: "La palma" },
            { id: "yW1dvY5SOaYuhnRDyX9f", nombre: "Oskars Campestre" },
            { id: "fnjVssmfl0od5cJaaoTt", nombre: "La Sucursal" },
            { id: "4rwjAqpjREqPniAZGtVW", nombre: "La Octava Maravilla" },
            { id: "xbhJ5bnh0FRc00vJali8", nombre: "La montaña que piensa" },
            { id: "CE0HrXmQh29QpGzjzmHd", nombre: "HD 360" },
        ]
    },
    {
        id: 'almorzar.los.domingos', name: 'Almorzar los Domingos', description: "Colección especial", miradoresId: [
            { id: "tVOyK1ZWbDfMvL4D7IMB", nombre: "La Bendición" },
            { id: "OSbvSNtTKoK5ewHuppPE", nombre: "La Torre Picnic" },
            { id: "BXN7AaLBfygdHkNXd4OP", nombre: "San Ateo" },
            { id: "V9ecEayUzYG35TxxA7VY", nombre: "Pachamama Ancestral" },
            { id: "qpPifnJxlwvZPUl3MjS8", nombre: "Michigan" },
            { id: "5ezZUlMUexX0WSWdsuin", nombre: "El encanto" },
            { id: "lMNhtm9PVQSRhT4RycP6", nombre: "La palma" },
            { id: "yW1dvY5SOaYuhnRDyX9f", nombre: "Oskars Campestre" },
            { id: "fnjVssmfl0od5cJaaoTt", nombre: "La Sucursal" },
            { id: "4rwjAqpjREqPniAZGtVW", nombre: "La Octava Maravilla" },
            { id: "xbhJ5bnh0FRc00vJali8", nombre: "La montaña que piensa" },
            { id: "CE0HrXmQh29QpGzjzmHd", nombre: "HD 360" },
        ]
    },
];


