import { Pipe, PipeTransform } from '@angular/core';
import { Moment } from 'moment';

@Pipe({
  name: 'momentToDate'
})
export class MomentToDatePipe implements PipeTransform {

  transform(value: Moment, ...args: unknown[]): unknown {
    if(args[0]) {
      return value.format('hh:mm a');
    }
    return value.format('h a');
  }

}
