import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Mirador } from '../models/mirador';
import { MiradorSaved } from '../models/mirador-save';
import { User } from '../models/user';
import { Visit, VisitGroup } from '../models/visit';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { UtilService } from './util.service';

const TABLE = "visits";
const FIRESTORAGE_BASE_PATH = "visits"

@Injectable({
  providedIn: 'root'
})
export class VisitsManagerService {


  constructor(public fireStore: AngularFirestore,
     public fireStorage: AngularFireStorage, 
     private ng2ImgMax: Ng2ImgMaxService,
     private utilService: UtilService,
     
    ) {
   }

  SetVisitData(user: User, mirador: Mirador, urlPhoto: string) : Promise<void> {
    const visitId = this.fireStore.createId();
    const visitRef: AngularFirestoreDocument<any> = this.fireStore.doc(
      `${TABLE}/${visitId}`
    );

    const visitData: Visit = {
      visitId: visitId,
      userId: user.uid,
      miradorId: mirador.miradorId,
      urlPhoto: urlPhoto,
      date: new Date(),
      approvalStatus: 'pendingApproval'
    };
    return visitRef.set(visitData, {
      merge: true,
    }).then(() => {

    });
  }

  updatedVisitStatus (visit, newStatus) : Promise<void> {
    const visitRef: AngularFirestoreDocument<any> = this.fireStore.doc(
      `${TABLE}/${visit.visitId}`
    );
    return visitRef.update({"approvalStatus": newStatus});
  }


  removeSave(visitId) {
    const itemDoc =  this.fireStore.doc(
      `${TABLE}/${visitId}`
    );
    return itemDoc.delete();
  }

  getMyMiradoresVisited(user: User) : Observable<(Visit)[]> {
    if(user && user.uid){
      return this.fireStore.collection<Visit>(TABLE, ref => ref
        .where('userId' , '==' , user.uid)
        .where('approvalStatus','in',['pendingApproval','approved']))
        .valueChanges({idField: 'visitId'});
    }else {
      return of([]);
    }
  }

  getMyMiradoresVisitedAll(user: User) : Observable<(Visit)[]> {
    if(user && user.uid){
      return this.fireStore.collection<Visit>(TABLE, ref => ref
        .where('userId' , '==' , user.uid))
        .valueChanges({idField: 'visitId'});
    }else {
      return of([]);
    }
  }

  getMiradorVisits(mirador: Mirador) : Observable<(Visit)[]> {
    if(mirador && mirador.miradorId){
      return this.fireStore.collection<Visit>(TABLE, ref => ref
      .where('miradorId' , '==' , mirador.miradorId)
      .where('approvalStatus','in',['pendingApproval','approved'])
      .orderBy('date', 'desc'))
      .valueChanges({idField: 'visitId'});
    }else {
      return of([]);
    }

  }

  getAllNewVisits() : Observable<(Visit)[]> {
    return this.fireStore.collection<Visit>(TABLE, ref => ref.where('approvalStatus' , '==' , 'pendingApproval'))
      .valueChanges({idField: 'visitId'});
  }

  getAllNewVisitsXWeeksAgo(weeksAgo: number) : Observable<(Visit)[]> {
    let startDate = this.utilService.getMomentObj().subtract(weeksAgo, 'weeks').startOf('day').toDate();
    let endDate = this.utilService.getMomentObj().endOf('day').toDate();
    
    return this.fireStore.collection<Visit>(TABLE, ref =>
      ref.where('date', '>=', startDate)
        .where('date', '<=', endDate)
        .where('approvalStatus' , '==' , 'pendingApproval')
    ).valueChanges({idField: 'visitId'});
  }

 uploadImageFromVisit(file: any, userId: string, filename: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      const cleanedBase64 = file.replace(/^data:image\/\w+;base64,/, '');

      const blob = this.base64ToBlob(cleanedBase64, 'image/png'); // Convierte la cadena base64 a Blob
      const fileObject = new File([blob], filename, { type: 'image/png' }); // Crea un objeto File

      // Comprime la imagen usando Ng2ImgMaxService 500kb max
      this.ng2ImgMax.compressImage(fileObject, 0.5, true).subscribe({
        next: (result) => {
          const compressedFile = new File([result], filename, { type: 'image/png' });
          const filePath = `${FIRESTORAGE_BASE_PATH}/${userId}/${filename}`;
          const ref = this.fireStorage.ref(filePath);
          const task = ref.put(compressedFile);

          task.snapshotChanges().pipe(
            finalize(() => {
              ref.getDownloadURL().subscribe({
                next: (url) => {
                  resolve(url);
                },
                error: (getUrlError) => {
                  reject(getUrlError);
                },
              });
            })
          ).subscribe();
        },
        error: (compressionError) => {
          console.error('Error al procesar la imagen:', compressionError);
          reject(compressionError);
        },
      });

    });
  }

  base64ToBlob(base64: string, type: string): Blob {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: type });
  }


  // UTIL

  groupVisitsByMiradorId(data) {

    return data.reduce((acc : VisitGroup[], visit: Visit ) => {

      const item = acc.find((el) => el.miradorId === visit.miradorId);

      if (item) {
        item.visits.push(visit);
        if(item.lastVisitDate < visit.date) {
          item.lastVisitDate = visit.date;
          item.lastVisitId = visit.visitId;
        }
      }
      else {
        acc.push({ miradorId: visit.miradorId, lastVisitId: visit.visitId, lastVisitDate: visit.date, visits: [visit] });
      }

      return acc;
    }, []);
  }

  updatedVisitLikesCount (visitId, newCount) : Promise<void> {
  
    const visitRef: AngularFirestoreDocument<any> = this.fireStore.doc(
      `${TABLE}/${visitId}`
    );
    return visitRef.update({"countLikes": newCount});
  }
}
