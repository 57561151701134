import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberWithDot'
})
export class NumberWithDotPipe implements PipeTransform {

  transform(value: number): string {
    return new Intl.NumberFormat('es-ES').format(value);
}

}
