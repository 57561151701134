import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BusinessHour } from '../models/business-hour';
import { Moment } from 'moment';
import { BehaviorSubject } from 'rxjs';
import { Timestamp } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  private isDarkMode = new BehaviorSubject<boolean>(false);
  public $isDarkMode = this.isDarkMode.asObservable();

  constructor() { }


  public setTheme(status: boolean) {
    if(this.isDarkMode.value != status)
    this.isDarkMode.next(status);
  }


  isOpen(bussinesHour: BusinessHour): boolean {
    let isCurrentOpen = false;
    if (bussinesHour.type == 1) {
      return true;
    }

    if (bussinesHour.labelForToday) {
      isCurrentOpen = this.getMomentObj().isBetween(bussinesHour.startHourToday, bussinesHour.endHourToday);
    }

    return isCurrentOpen;
  }

  getContentByDayOfWeek(bussinesHour: BusinessHour, dayOfWeek: string): string {
    let existThatDay = bussinesHour.contentSplitted.find((x) =>
      x.toLowerCase().includes(dayOfWeek)
    );

    if(existThatDay == undefined) {
      existThatDay = dayOfWeek + ' | Cerrado'
    }
    return existThatDay;
  }

  getStartHourByContent(content: string, isBefore = false) {
    let timeSection = this.getStringHourByContent(content);
    let startHour = this.getHourByString(timeSection.substring(0, timeSection.indexOf('-')));
    return isBefore ? startHour.subtract(1, 'days') : startHour;
  }

  getEndHourByContent(content: string, startHour: Moment, isBefore = false) {
    let timeSection = this.getStringHourByContent(content);
    let endHour = this.getHourByString(timeSection.substring(timeSection.indexOf('-')));

    endHour = isBefore ? endHour.subtract(1, 'days') : endHour;

    if(endHour < startHour) {
      endHour = endHour.add(1, 'day');
    }
    return endHour;
  }

  getHourByString(stringHour: string) : Moment {
    stringHour = stringHour.trim();
    return moment(stringHour, 'HH:mm');
  }

  getStringHourByContent(content: string) : string {
    let timeSection = content;
    if(content.includes('|')) {
      timeSection = content.split(' | ')[1];
    }
    return timeSection;
  }
     

  RangeDaysMatchWithCurrentOne(content, dayOfWeek) {
    let daysSection : string[] = content.split(':')[0].split(' a ');
    if(daysSection.length > 1){
      let firstDay = this.getMomentObj().day(daysSection[0]).startOf('day');
      let lastDay = this.getMomentObj().day(daysSection[1]).endOf('day');

      return this.getMomentObj().isBetween(firstDay, lastDay);
    }
    return false;
  }

  anyDateHaveMinutes(...dates:Moment[]) {
    let result = false;
    dates.map(d => {
      if(d.minutes() > 0) {
        result = true;
      }
    })

    return result;
  }

  getTodayAsString() {
    return this.getMomentObj().format('dddd');
  }

  getYesterdayAsString() {
    return this.getMomentObj().subtract(1, 'days').format('dddd');
  }

  isBeforeToSixAM(){
    return this.getMomentObj().isBefore(this.getMomentObj().hours(6).minutes(0));
  }

  isAfterTo24Hours(date: Timestamp) {
 
    return moment().diff(moment(date.toDate()), 'hours') > 24;
  }

  getMommentByDate(date: Date){
    return moment(date);
  }

  getMomentObj() {
    return moment().locale('es-CO');
  }


// Normalize URLS Funtions
public getDesnormalizedNameForURL(value: string) {
  return this.removeAccents(value.trim().toLowerCase().split ('.').join (' '));
}

public getNormalizedNameForURL(value: string) {
  return this.removeAccents(value.trim().toLowerCase().replace(/ /g, "."));
}

public removeAccents(value: string) {
  return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

// Generate a random number between 0 and 'n'
public getRandomNumber(n: number): number {
  return Math.floor(Math.random() * (n + 1));
}

public getNormalizedMiradorNameForURL(value: string) {
  return this.removeAccents(value.trim().toLowerCase().replace(/ /g, "."));
}

}
