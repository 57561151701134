import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safevideo'
})
export class SafevideoPipe implements PipeTransform {

  /**
   *
   */
  constructor(private dom: DomSanitizer) {
  }

  transform(value: string, ...args: any[]) : SafeResourceUrl {
    return this.dom.bypassSecurityTrustResourceUrl(value);
  }

}
