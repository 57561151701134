import { Injectable } from '@angular/core';
import { Experience, MarkersExperience } from '../models/ToursModels';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable, Subscription, of, take, } from 'rxjs';
import { ViewsExperiences } from '../models/views-experiences';
import { citiesAndRegions } from '../enums & constants/main-citys-regions';
import { CityRegion } from '../models/city-region';
import { ExperienceFilter } from '../models/experience-list';
import { ReserveService } from './reserve.service';
import { EventsService } from './events.service';

const TABLE = "experiences";

@Injectable({
  providedIn: 'root'
})
export class ExperienceService {

  citiesAndRegions: CityRegion[] = citiesAndRegions;

  private unfilteredExperiences: Experience[] = [];
  private allExperiences: Experience[] = [];
  private experiencesProvider = new BehaviorSubject<Experience[]>([]);
  public $allExperiences = this.experiencesProvider.asObservable();
  public getAllExperiencesSubscription: Subscription;

  private cityRegionSelectedSubject = new BehaviorSubject<CityRegion | null>(null);
  cityRegionSelectedSubject$ = this.cityRegionSelectedSubject.asObservable();

  private experiencesByCityRegionSubject = new BehaviorSubject<Experience[]>([]);
  experiencesByCityRegion$ = this.experiencesByCityRegionSubject.asObservable();

  private experiencesLoaded = new BehaviorSubject<boolean>(false);
  public $experiencesLoaded = this.experiencesLoaded.asObservable();

  private markerExperienceSelected = new BehaviorSubject<MarkersExperience | null>(null);
  public $markerExperienceSelected = this.markerExperienceSelected.asObservable();

  private readonly localStorageKey = 'viewsExperiences';

  public isFirstLoad = true;

  constructor(public fireStore: AngularFirestore
  ) {
    this.getExperiences();
    this.$experiencesLoaded.subscribe((isDone) => {
      if (isDone) {
        const defaultCityRegion = this.citiesAndRegions[0];
        this.setCityRegion(defaultCityRegion);
      }
    });
  }

  getExperiences() {
    this.getAllExperiencesSubscription = this.fireStore.collection<Experience>(TABLE, ref => ref
      .orderBy("score", 'desc'))
      .valueChanges({ idField: '_id' }).subscribe((data) => {
        if (data.length) {
          this.unfilteredExperiences = data;

          let onlyActiveExperiences = data.filter((item) => {
            return item?.state;
          });
          // console.log({data, onlyActiveExperiences});
          this.allExperiences = onlyActiveExperiences.map((experienceData) => new Experience(experienceData));
          this.experiencesProvider.next(this.allExperiences);
          this.getAllExperiencesSubscription.unsubscribe();
          this.experiencesLoaded.next(true);
        }
      });
  }

  setCityRegion(cityRegion: CityRegion): void {
    this.cityRegionSelectedSubject.next(cityRegion);
    this.setExperiencesByCityRegion(cityRegion);

  }

  setExperiencesByCityRegion(cityRegion: CityRegion): void {
    const experiencesByCityRegion = this.experiencesProvider.value.filter(exp => exp.mainCityRegionId === cityRegion.id);
    this.experiencesByCityRegionSubject.next(experiencesByCityRegion);
  }




  // Método para obtener todos los álbumes
  getAllCitiesRegion(): CityRegion[] {
    return citiesAndRegions;
  }

  // Método para obtener la información de un álbum por su ID
  getCityRegionById(cityRegionId: string): CityRegion {
    const cityRegion = this.citiesAndRegions.find(a => a.id === cityRegionId);
    return cityRegion;
  }



  public getExperienceByID(ID: string): any {
    return this.allExperiences.find(experience => {
      return experience._id == ID;
    })
  }

  public getUnfilteredExperienceByID(ID: string): any {
    return this.unfilteredExperiences.find(experience => {
      return experience._id == ID;
    });
  }



  deleteExperience(_id: any) {
    const itemDoc = this.fireStore.doc(
      `${TABLE}/${_id}`
    );


    return itemDoc.delete();
  }

  saveExperience(newExperience: Experience, isNew = true) {
    let experienceId;
    if (isNew) {
      experienceId = this.fireStore.createId();
      newExperience._id = experienceId;

    } else {
      experienceId = newExperience._id;
    }

    const experienceRef: AngularFirestoreDocument<any> = this.fireStore.doc(
      `${TABLE}/${experienceId}`
    );

    return experienceRef.set({ ...newExperience }, {
      merge: true,
    }).then(() => {
      if (isNew) {
        this.experiencesProvider.next([...this.experiencesProvider.value, newExperience]);
      }

      return newExperience;
    });
  }

  syncExperiences(allData) {
    this.experiencesProvider.next(allData);
  }

  trackExperienceVisit(experienceId: string, userId: string | null) {
    const timestamp = new Date();

    const visits = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
    const visitKey = this.generateVisitKey(experienceId, userId);

    if (!visits[visitKey]) {
      this.saveVisitToFirestore(experienceId, userId, timestamp);
    } else {
      const lastVisitTimestamp = new Date(visits[visitKey]).getTime();
      const currentTimestamp = new Date().getTime();
      const oneHour = 60 * 60 * 1000;

      if (currentTimestamp - lastVisitTimestamp >= oneHour) {
        this.saveVisitToFirestore(experienceId, userId, timestamp);
      }
    }

    visits[visitKey] = timestamp;
    localStorage.setItem(this.localStorageKey, JSON.stringify(visits));
  }

  async saveVisitToFirestore(
    experienceId: string,
    userId: string | null,
    timestamp: Date
  ) {
    try {
      const visitData: ViewsExperiences = {
        experienceId: experienceId,
        userId: userId,
        timestamp: timestamp
      };

      await this.fireStore.collection<ViewsExperiences>('view-experiences').add(visitData);
    } catch (error) {
      console.error('Error al guardar la visita en Firestore:', error);
    }
  }

  generateVisitKey(experienceId: string, userId: string | null): string {
    return userId ? `${experienceId}-${userId}` : experienceId;
  }

  getAllViewsExperiences(): Observable<any> {
    const oneHourAgo = new Date(Date.now() - 60 * 60 * 1000);

    return this.fireStore.collection<ViewsExperiences[]>('view-experiences', ref =>
      ref.where('timestamp', '>=', oneHourAgo)
    ).valueChanges();
  }

  public setMarkersExperienceSelected(selected: MarkersExperience) {
    this.markerExperienceSelected.next(selected);
  }

  // Función para actualizar mainCityRegionId y city a Medellín en la experiencia con el ID específico
  actualizarExperiencia(): void {
    // Obtener una referencia a la colección "experiences"
    const experiencesRef = this.fireStore.collection(TABLE);

    // Actualizar mainCityRegionId y city a Medellín en todas las experiencias
    experiencesRef.get().subscribe(snapshot => {
      snapshot.forEach(doc => {
        // Actualizar cada documento individualmente
        doc.ref.update({ mainCityRegionId: 'medellin', city: 'Medellín' });
      });
    });
  }




  public getExperiencesByFilters(newFilters) {
    const allExperiences = this.experiencesByCityRegionSubject.value.filter(exp => 
      exp.experienceType !== 'COLECTIVE_FIXED_PRICE_RESERVATION' && 
      exp.experienceType !== 'COLECTIVE_FREE_RESERVATION'
    );

    if (allExperiences.length > 0) {
      const expFilters = allExperiences.filter((experience) => {
        return this.matchAnyFilter(experience, newFilters);
      });
      return expFilters;
    } else {
      return [];
    }
  }

  private matchAnyFilter(experience: Experience, experienceFilters: ExperienceFilter[]): boolean {
    let isMatched = false;

    for (const element of experienceFilters) {
      let evalProp = this.getPropertyValueWithBracketsNotation(experience, element.type) as string;

      if (evalProp) {
        if (Array.isArray(evalProp)) {
          evalProp = evalProp.join();
        }

        if (this.getNormalizedString(evalProp).includes(this.getNormalizedString(element.value))) {
          isMatched = true;
          break;
        }
      }
    }
    return isMatched;
  }

  public getPropertyValueWithBracketsNotation(object: any, property: string) {
    if (!property.includes('.')) {
      return object[property];
    }

    let splitProperty = property.split('.', 2);

    return this.getPropertyValueWithBracketsNotation(object[splitProperty[0]], splitProperty[1]);
  }

  public getNormalizedString(value: string) {
    return this.removeAccents(value.trim().toLowerCase().replace(/ /g, "."));
  }

  public removeAccents(value: string) {
    return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  checkFirstLoad(): boolean {
    if (this.isFirstLoad) {
      this.isFirstLoad = false;
      return true;
    }
    return false;
  }


}
