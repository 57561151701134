import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable, of } from 'rxjs';
import { Mirador } from '../models/mirador';
import { MiradorLiked } from '../models/mirador-like';
import { User } from '../models/user';

const TABLE = "likes";

@Injectable({
  providedIn: 'root'
})
export class LikeManagerService {

  constructor(public fireStore: AngularFirestore) {

  }

  getLikesByUser(user: User) : Observable<(MiradorLiked)[]> {
    if(user && user.uid){
      return this.fireStore.collection<MiradorLiked>(TABLE, ref => ref
        .where('userId' , '==' , user.uid)) 
        .valueChanges({idField: 'miradorLikedId'});       
    }else {
      return of([]);
    }
  }


  SetLikedData(user: User, mirador: Mirador) : Promise<void> {
    const likeId = this.fireStore.createId();
    const likeRef: AngularFirestoreDocument<any> = this.fireStore.doc(
      `${TABLE}/${likeId}`
    );
    const likedData: MiradorLiked = {
      likeId: likeId,
      userId: user.uid,
      miradorId: mirador.miradorId
    };
    return likeRef.set(likedData, {
      merge: true,
    }).then(() => {
      if(!mirador.likes) {
        mirador.likes = [likedData];
      } else {
        mirador.likes.push(likedData);
      }
    });
  }

  getAmountOfLikesByMirador(mirador: Mirador) : Observable<(MiradorLiked & { likeId: string; })[]> {
    return this.fireStore.collection<MiradorLiked>(TABLE, ref => ref.where('miradorId' , '==' , mirador.miradorId))
    .valueChanges({idField: 'likeId'})    
  }

  removeLike(likeId) {
    const itemDoc =  this.fireStore.doc(
      `${TABLE}/${likeId}`
    );
    return itemDoc.delete();
  }
}
